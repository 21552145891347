@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=2dda6fd5-8059-4574-a834-d5912b7d9220");
@font-face {
  font-family: "Helvetica Neue LT W05_55 Roman";
  src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix");
  src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix")
      format("eot"),
    url("../fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"),
    url("../fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"),
    url("../fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica Neue LT W01_75 Bold";
  src: url("../fonts/9697cfef-7816-47cc-81ed-c56c029d3bcf.eot?#iefix");
  src: url("../fonts/9697cfef-7816-47cc-81ed-c56c029d3bcf.eot?#iefix")
      format("eot"),
    url("../fonts/1f4274cd-2674-481e-9023-69e33ffca161.woff2") format("woff2"),
    url("../fonts/5e8d493c-be01-44b9-962e-504a5cf10dd8.woff") format("woff"),
    url("../fonts/ae36b4a0-aa2c-46cc-98d8-d8b7d22241dc.ttf") format("truetype");
}
