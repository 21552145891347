@import "css/overrides.css";
html {
  -webkit-text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  /* overflow-x: hidden;  */
}

body,
#cfo-wrapper {
  background: #ffffff;
  color: #000000;
  font-family: "Helvetica Neue LT W05_55 Roman", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

body.dragging,
body.dragging * {
  cursor: move !important;
}

#customs-form-2019-main {
  padding: 0px 0;
}

strong,
b {
  color: #000;
}

a {
  color: #3573b1;
  text-decoration: underline;
}

a:hover {
  color: #3573b1;
  text-decoration: none;
}

a strong,
a:hover strong {
  color: inherit;
}

ul li {
  font-size: 16px;
  line-height: 22px;
  padding: 5px 0;
}

input,
select,
textarea,
.fieldset select {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #578bc8;
  color: #1c1c1b;
  font-size: 14px;
  line-height: 19px;
  max-width: 100%;
  padding: 5px 8px;
}

input[type="image"] {
  background: 0;
  border-radius: 0;
  border: none;
  padding: 0;
  width: auto;
}

input[type="submit"] {
  -webkit-appearance: none;
  background: #333;
  border-radius: 0;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 19px;
  padding: 5px 8px;
  transition: all 500ms ease;
}

input[type="submit"]:hover {
  -webkit-appearance: none;
  background: #000;
}

h1 {
  color: #333366;
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 25px;
}

h2 {
  color: #333366;
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 15px;
}

h2 span {
  font-weight: 300;
}

h3 {
  color: #333366;
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 10px !important;
}

h3 span {
  font-weight: 300;
}

h4 {
  color: #333366;
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin-bottom: 7px;
}

.color-white,
.color-white h1,
.color-white h2,
.color-white h3,
.color-white h4,
.color-white h5,
.color-white h6,
.white-headline,
.white-headline h1,
.white-headline h2,
.white-headline h3,
.white-headline h4,
.white-headline h5,
.white-headline h6 {
  color: #fff;
}
.color-black,
.color-black h1,
.color-black h2,
.color-black h3,
.color-black h4,
.color-black h5,
.color-black h6,
.black-headline,
.black-headline h1,
.black-headline h2,
.black-headline h3,
.black-headline h4,
.black-headline h5,
.black-headline h6 {
  color: #000;
}

#middle {
  margin: 0;
  padding: 25px 0;
}

.form-group {
  margin-bottom: 1rem;
}

.w-100 {
  width: 100% !important;
}

.form-group .dropdown {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .button-container {
    display: inline-block;
  }

  .button-container {
    padding-top: 30px;
  }
}
.form-group .dropdown-menu {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-color: #336;
  border-left-color: rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  border-right-color: rgba(0, 0, 0, 0.15);
  border-top-color: rgba(0, 0, 0, 0.15);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top: 0;
  color: #595959;
  display: none;
  float: left;
  font-size: 0.875rem;
  left: 0;
  list-style: none;
  margin-top: -10px;
  margin-top: 0.125rem;
  margin: 0.125rem 0 0;
  max-height: 350px;
  min-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  top: 100%;
  width: 100%;
  z-index: 1;
}

.form-group .active .dropdown-menu {
  display: block;
}

.form-group .input-group .input-with-units {
  border-right: none;
}

.form-group .input-group .input-with-dollar {
  border-left: none;
}

.form-group .input-group .input-units,
.form-group .input-group .input-dollar {
  align-items: center;
  background-color: #fff;
  border: 1px solid;
  border-color: #333366;
  color: #333366;
  display: flex;
  -webkit-display: flex;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  height: 44px;
  padding-left: 10px;
  padding-right: 10px;
}

.form-group .input-group .input-units {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-left: none;
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
}

.form-group .input-group .input-dollar {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 0;
}

.form-group.has-error .field-error {
  display: block;
}

.form-group.has-error .form-control {
  border-color: #e71921;
}

.is-required:before {
  content: "*";
}

.pre-form-error {
  color: #e71921;
  margin-bottom: 2em;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
}

.form-control {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #336;
  color: #333366;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: auto;
  line-height: 1.5;
  padding: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

input[type="date"].form-control {
  background: #fff url(images/icon-calendar.svg) no-repeat right 10px center;
}

.form-control:focus {
  border-color: #6666b3;
  box-shadow: 0 0 0 2px #336;
  color: #333366;
  outline: 0;
}

.form-control-read-only {
  background: 0;
  border: none;
  margin: 0;
  padding: 10px 0;
}

.form-control.single-line-textarea {
  height: 43px;
}

.form-control.two-line-textarea {
  height: 64px;
}

.usps-checkbox,
.checkbox-field {
  font-size: 14px;
}

/* usps checkboxes */

.usps-checkbox > [type="checkbox"],
.usps-checkbox > label,
.usps-checkbox > .usps-checkbox-box {
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  margin-bottom: 0px !important;
  user-select: none;
}

.usps-checkbox > [type="checkbox"]:not(:checked),
.usps-checkbox > [type="checkbox"]:checked {
  left: -9999px;
  position: absolute;
  visibility: hidden;
}

.usps-checkbox > [type="checkbox"]:not(:checked) + label,
.usps-checkbox > [type="checkbox"]:checked + label,
.usps-checkbox > [type="checkbox"]:not(:checked) + .usps-checkbox-box,
.usps-checkbox > [type="checkbox"]:checked + .usps-checkbox-box {
  cursor: pointer;
  padding-left: 26px;
  position: relative;
}

.usps-checkbox > [type="checkbox"]:not(:checked) + label:before,
.usps-checkbox > [type="checkbox"]:checked + label:before,
.usps-checkbox > [type="checkbox"]:not(:checked) + .usps-checkbox-box:before,
.usps-checkbox > [type="checkbox"]:checked + .usps-checkbox-box:before {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #999999;
  content: "";
  height: 18px;
  left: 0;
  margin-top: -9px;
  position: absolute;
  top: 10px;
  width: 18px;
}

.terms-checkbox .usps-checkbox > [type="checkbox"]:not(:checked) + label:before,
.terms-checkbox .usps-checkbox > [type="checkbox"]:checked + label:before,
.terms-checkbox
  .usps-checkbox
  > [type="checkbox"]:not(:checked)
  + .usps-checkbox-box:before,
.terms-checkbox
  .usps-checkbox
  > [type="checkbox"]:checked
  + .usps-checkbox-box:before {
  margin-top: 2px;
  top: 0;
}

.usps-checkbox > [type="checkbox"]:not(:checked) + label:after,
.usps-checkbox > [type="checkbox"]:checked + label:after,
.usps-checkbox > [type="checkbox"]:not(:checked) + .usps-checkbox-box:after,
.usps-checkbox > [type="checkbox"]:checked + .usps-checkbox-box:after {
  background-image: url("images/check.svg");
  background-repeat: no-repeat;
  background-size: contain;
  color: #333366;
  content: " ";
  height: 12px;
  left: 3px;
  margin-top: -5px;
  position: absolute;
  top: 10px;
  transition: all 0.2s;
  width: 12px;
}

.terms-checkbox .usps-checkbox > [type="checkbox"]:not(:checked) + label:after,
.terms-checkbox .usps-checkbox > [type="checkbox"]:checked + label:after,
.terms-checkbox
  .usps-checkbox
  > [type="checkbox"]:not(:checked)
  + .usps-checkbox-box:after,
.terms-checkbox
  .usps-checkbox
  > [type="checkbox"]:checked
  + .usps-checkbox-box:after {
  margin-top: 6px;
  top: 0;
}

.usps-checkbox > [type="checkbox"]:not(:checked) + label:after,
.usps-checkbox > [type="checkbox"]:not(:checked) + .usps-checkbox-box:after {
  opacity: 0;
  transform: scale(0);
}

.usps-checkbox > [type="checkbox"]:checked + label:after,
.usps-checkbox > [type="checkbox"]:checked + .usps-checkbox-box:after {
  opacity: 1;
  transform: scale(1);
}

.usps-checkbox > [type="checkbox"][data-indeterminate] + label:after,
.usps-checkbox > [type="checkbox"][data-indeterminate] + label:after,
.usps-checkbox
  > [type="checkbox"][data-indeterminate]
  + .usps-checkbox-box:after,
.usps-checkbox
  > [type="checkbox"][data-indeterminate]
  + .usps-checkbox-box:after {
  content: "\2212";
  left: 2px;
  opacity: 1;
  transform: scale(1);
}

.usps-checkbox > [type="checkbox"]:disabled:not(:checked) + label:before,
.usps-checkbox > [type="checkbox"]:disabled:checked + label:before,
.usps-checkbox
  > [type="checkbox"]:disabled:not(:checked)
  + .usps-checkbox-box:before,
.usps-checkbox
  > [type="checkbox"]:disabled:checked
  + .usps-checkbox-box:before {
  background-color: #eeeeee;
  border-color: #eeeeee;
  box-shadow: none;
  color: #dadada;
  opacity: 1;
}

.usps-checkbox > [type="checkbox"]:disabled:checked + label:after,
.usps-checkbox > [type="checkbox"]:disabled:checked + .usps-checkbox-box:after {
  color: #dadada;
  cursor: default;
}

.usps-checkbox > [type="checkbox"]:disabled + label,
.usps-checkbox > [type="checkbox"]:disabled + .usps-checkbox-box {
  color: #aaa;
  cursor: default;
}

.usps-checkbox > [type="checkbox"]:checked:focus + label:before,
.usps-checkbox > [type="checkbox"]:not(:checked):focus + label:before,
.usps-checkbox > [type="checkbox"]:checked:focus + .usps-checkbox-box:before,
.usps-checkbox
  > [type="checkbox"]:not(:checked):focus
  + .usps-checkbox-box:before {
  border: 1px solid #333366;
}

.usps-checkbox > label:hover:before {
  border: 1px solid #949494 !important;
}

.usps-checkbox > [type="checkbox"]:disabled:not(:checked) + label:hover:before,
.usps-checkbox > [type="checkbox"]:disabled:checked + label:hover:before,
.usps-checkbox
  > [type="checkbox"]:disabled:not(:checked)
  + .usps-checkbox-box:hover:before,
.usps-checkbox
  > [type="checkbox"]:disabled:checked
  + .usps-checkbox-box:hover:before {
  border: 1px solid #eeeeee !important;
  cursor: default;
}

.usps-checkbox-select-all label {
  color: #000;
  font-family: "Helvetica Neue Bold", Helvetica, Arial, sans-serif;
}

.usps-checkbox-select-all {
  margin-top: 2em;
}

.more-info {
  display: inline-block;
  position: relative;
}

.more-info-link {
  display: inline-block;
}

.inline-label-wrapper label {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.btn-primary,
.btn.previous,
.btn-cancel,
.btn-history-clear,
.btn-back {
  font-family: "Helvetica Neue LT W01_75 Bold";
  min-width: 120px;
}

.btn,
.usps-segmented-control .usps-radio-button {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  background-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 0.25rem;
  border-right-color: transparent;
  border-top-color: transparent;
  border: 1px solid transparent;
  color: #595959;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.65rem 1.3rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
}

.btn-outline-primary,
.btn-add-item,
.usps-segmented-control .usps-radio-button {
  border-color: #336;
  color: #336;
}

.btn-outline-primary {
  background-color: #fff;
}

.btn-add-item *,
.btn-edit-item *,
.btn-remove-item * {
  vertical-align: top;
}

.btn-add-item:before {
  background: transparent url(images/icon-plus.svg) no-repeat left top / contain;
  content: "";
  display: inline-block;
  height: 14px;
  margin-left: 5px;
  margin-top: 3px;
  width: 14px;
}

.btn-edit-item:before {
  background: transparent url(images/button-edit.svg) no-repeat left top /
    contain;
  content: "";
  display: inline-block;
  height: 14px;
  margin-right: 5px;
  margin-top: 3px;
  width: 14px;
}

.btn-remove-item:before {
  background: transparent url(images/button-delete.svg) no-repeat left top /
    contain;
  content: "";
  display: inline-block;
  height: 14px;
  margin-right: 0px;
  margin-top: 3px;
  width: 14px;
}

.btn-x:before {
  background: transparent url(images/icon-close.svg) no-repeat left top /
    contain;
  content: "";
  float: left;
  height: 14px;
  margin-right: 5px;
  margin-top: 3px;
  width: 14px;
}

.btn-add:after {
  background: transparent url(images/icon-plus-red.svg) no-repeat left top /
    contain;
  content: "";
  float: right;
  height: 13px;
  margin-left: 5px;
  margin-top: 3px;
  width: 13px;
}

.btn {
  cursor: pointer;
}

.btn.btn-block {
  display: block;
}

.btn-text {
  appearance: none;
  background: transparent;
  border: none;
  color: #336;
  cursor: pointer;
  padding: 0.65rem 1.3rem;
}

.btn-text:hover {
  color: #595959;
}

.btn-text:active,
.btn-text:focus {
  outline: none;
}

.btn-chromeless {
  appearance: none;
  background: transparent;
  border: none;
  color: #333366;
  cursor: pointer;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 0;
}

.btn-chromeless:active,
.btn-chromeless:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.btn-primary {
  background-color: #333366;
  border-color: #333366;
  color: #fff;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  color: #336;
}

.btn-edit {
  color: #3573b1;
  text-decoration: underline;
}

.has-edit-button {
  display: inline-block;
}

.has-edit-button + a,
.has-edit-button button {
  display: inline-block;
  margin-left: 10px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  color: #999;
}

.btn-cancel {
  background-color: transparent;
  color: #336;
  font-size: 0.85rem;
  font-weight: normal;
}

.btn-outline-light:hover {
  color: #336;
}

.btn-white {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-white:hover {
  background-color: #fff;
  color: #336;
}

.btn-toggle:after {
  -webkit-transform: rotate(90deg);
  background: transparent url(images/blue-caret.svg) no-repeat right center /
    contain;
  content: "";
  display: inline-block;
  height: 15px;
  left: 10px;
  position: relative;
  top: 2px;
  transform: rotate(90deg);
  transition: all 0.2s cubic-bezier(0, 0.75, 0.05, 0.98);
  width: 10px;
}

.btn-toggle.active:after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 125, 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 125, 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #222244;
  border-color: #005cbf;
  color: #fff;
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #222244;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #222244 !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.dropdown-toggle {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-position-x: 0%;
  background-position-y: 0%;
  background-position: right 16px center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  /* background: url(images/down-carrat.svg); */
  background: url(images/blue-caret.svg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer;
  overflow: hidden;
  padding-right: 2.5rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.dropdown-toggle:focus,
.dropdown-toggle:hover,
.dropdown-toggle:active {
  background-color: #fff !important;
  border-color: #333366;
  box-shadow: none !important;
  color: #333366 !important;
}

.dropdown.active .dropdown-toggle {
  border-bottom: 1px solid transparent;
}

.dropdown-toggle:after {
  display: none;
}

.dropdown-item {
  -webkit-appearance: button;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  clear: both;
  color: #333366;
  cursor: pointer;
  display: block;
  display: block;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  padding: 0.68em 1.3rem;
  text-align: inherit;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  width: 100%;
}

.dropdown-item:hover {
  background: #333366;
  color: #fff;
}

#app-form {
  padding: 20px 0;
}

.section-row {
  margin-top: 30px;
}

.section-row:first-child {
  margin-top: 0;
}

.checklists {
  margin-top: 5px;
}

ul.check-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.check-list li {
  display: block;
  list-style: none;
  margin: 10px 0 0;
  padding: 0 0 0 17px;
  position: relative;
}

ul.check-list li:before {
  background: transparent url(images/check-green.svg) no-repeat left top /
    contain;
  color: #2b7636;
  content: "";
  display: block;
  height: 11px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 13px;
}

.progress-example,
.note,
.field-desc {
  font-size: 85%;
}

.customs-privacy-policy {
  font-size: 10px;
}

.denied-party-text {
  font-size: 10px;
}

#generate-aes-itn-link:not(.hidden) {
  align-items: center;
  display: flex;
  font-family: "Helvetica Neue LT W01_75 Bold";
}

label.error {
  color: #e71921;
  font-size: 85%;
}

.restrictions-prohibitions {
  border: 1px solid #333366;
  font-size: 12px;
  padding: 15px;
}

/* .row-dependent {
  display: none;
} */

.field-wrapper {
  background: #fff;
  position: relative;
}

#items-rows .items-row.row-locked:nth-child(2n) .field-wrapper {
  background: #f7f7f7;
}

.field-icon {
  color: #333366;
  position: absolute;
  top: 11px;
  z-index: 1;
}

.field-prefix {
  left: 0;
  padding-left: 8px;
}

.field-suffix {
  right: 0;
  padding-right: 8px;
}

.field-wrapper .form-control {
  background-color: transparent;
  position: relative;
  z-index: 3;
}

.prefix-is-1-chars .form-control {
  padding-left: 1.8rem;
}

.prefix-is-2-chars .form-control {
  padding-left: 1.7rem;
}

.suffix-is-2-chars .form-control {
  padding-right: 1.7rem;
}

.prefix-is-3-chars .form-control {
  padding-left: 2rem;
}

.suffix-is-3-chars .form-control {
  padding-right: 2rem;
}

.form-button-container {
  display: inline-block;
}

.form-button-container:not(:first-child) {
  margin-left: 30px;
}

.btn-submit {
  display: none;
}

.btn-submit.force-show {
  display: block;
}

.show-all-remove-buttons .btn-remove-container,
.show-all-remove-buttons .btn-remove-sort-row {
  display: block;
  margin-right: 0;
}

.items-row-item:nth-child(30) .btn-add-container {
  display: none !important;
}

.radio-button {
  clear: both;
  float: left;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
}

.radio-button:first-child {
  margin-top: 0;
}

.radio-input {
  float: left;
  padding-top: 1px;
  width: 15px;
}

.radio-label {
  float: left;
  padding-left: 5px;
  width: calc(100% - 15px);
}

.radio-button-inline {
  clear: none;
  display: inline-block;
  float: none;
  margin-left: 30px;
  margin-top: 0;
  width: auto;
}

.radio-button-inline:first-child {
  margin-left: 0;
}

.method-selection h2 {
  background: #333366;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  padding: 4px 10px;
  text-align: left;
  text-transform: uppercase;
}

.method-selection h2.bg-green {
  background-color: #218748;
}

.method-selection h2 span {
  color: #000;
  font-size: 85%;
}

.method-selection label {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  display: block;
  margin: 0;
  user-select: none;
}

.method-selection label input {
  display: none;
}

.method-selection label .method-selection-box {
  background: #fff;
  display: block;
  height: 100%;
  margin-bottom: 2px;
  padding: 5px 20px;
}

.method-selection label input:checked + .method-selection-box {
  background: #f7f7f7;
  margin-bottom: 0;
}

.method-name {
  color: #333366;
  font-family: "Helvetica Neue LT W01_75 Bold";
  font-size: 14px;
  margin-bottom: 5px;
}

.method-note {
  margin-bottom: 0;
}

.method-price {
  color: #333366;
  font-size: 25px;
  font-weight: normal;
  margin-top: 5px;
}

.method-image {
  position: relative;
}

.method-image img {
  display: block;
  margin: 0;
}

.method-image span {
  display: block;
}

.method-image span:after {
  background: transparent;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.customs-privacy-policy {
  border-top: 1px solid #d3d3d3;
  padding-bottom: 20px;
  padding-top: 20px;
}

.faux-textarea {
  border: 1px solid #ddd;
  font-size: 75%;
  height: 65px;
  overflow: auto;
  padding: 10px;
}

.expandable {
  display: none;
}

.btn-trigger {
  padding-right: 20px;
  position: relative;
}

.btn-trigger:after {
  background-image: url(images/accordion-carrat.svg);
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 8px;
  margin-top: -4px;
  position: absolute;
  right: 0;
  top: 50%;
  transition: all 0.2s cubic-bezier(0, 0.75, 0.05, 0.98);
  width: 15px;
}

.btn-trigger.active:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.items-control-row {
  font-size: 11px;
}

.items-row {
  border-top: 1px solid #cac9c9;
  font-size: 14px;
  margin-left: 0;
  margin-right: 0;
}

#items-rows .items-row {
  background: #f7f7f7;
}

#items-rows .items-row:nth-child(2n + 1) {
  background: #fff;
}

.item-cell {
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: right;
}

.items-row-header {
  border-bottom: 2px solid #e71921;
  border-top: none;
  color: #000;
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;
  margin-top: 15px;
}

.ic-item-description,
.ic-item-weight,
.ic-note,
.ic-item-tariff,
.ic-item-origin-country {
  text-align: left;
}

.ic-note a {
  font-size: 16px;
}

.ic-item-number-id {
  display: block;
  padding-top: 10px;
}

.items-final-row {
  color: #333366;
  font-family: "Helvetica Neue LT W01_75 Bold";
}

.items-weight-entered-text {
  display: none;
}

.items-row-item.row-locked .form-control {
  padding: 0;
}

.items-row-item.row-locked .field-prefix {
  padding-left: 0;
}

.items-row-item.row-locked .with-prefix .form-control {
  background-color: transparent;
  padding-left: 10px;
}

.items-row-item.row-locked .field-icon {
  top: 1px;
}

.items-row-item.row-locked .ic-item-number-id {
  padding-top: 0;
}

.ic-item-total-weight {
  text-align: left;
}

.items-final-row .ic-item-total-value,
.items-final-row .ic-item-total-weight {
  color: #333366;
}

.items-subtotal-row .ic-item-total-value .more-info,
.items-subtotal-row .ic-item-total-weight .more-info {
  margin-right: -21.38px;
  margin-left: 0;
}

.items-warning-row {
  padding: 10px 0;
}

.items-warning-row.active {
  display: flex;
}

.selected-edit-button {
  display: none;
}

.selected-note {
  color: #999;
  display: none;
  font-family: "Helvetica Neue LT W01_75 Bold";
  font-size: 16px;
}

.selected-value {
  color: #333366;
  display: none;
  font-family: "Helvetica Neue LT W01_75 Bold";
  font-size: 20px;
}

.autocomplete-suggestions,
.modal-body ul.autocomplete-suggestions {
  background: #fff;
  border: 1px solid #333366;
  cursor: pointer;
  max-height: 300px;
  overflow: auto;
  padding-left: 0px;
}

.autocomplete-suggestion {
  font-weight: normal;
  overflow: hidden;
  padding: 10px 10px;
  white-space: nowrap;
}

.autocomplete-no-suggestion {
  padding: 2px 5px;
}

.autocomplete-selected {
  background: #333366;
  color: #fff;
}

.autocomplete-suggestions strong {
  color: #000;
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;
}

.autocomplete-selected strong {
  color: #fff;
}

.autocomplete-group {
  border-bottom: 1px solid #000;
  color: #000;
  display: block;
  font-family: "Helvetica Neue LT W01_75 Bold";
  font-size: 16px;
  padding: 2px 5px;
}

.european-union-text {
  margin-top: 50px;
}

/* START: TLC */

.submit-hs-tariff-code-wrapper {
  align-items: center;
  display: flex;
  margin-left: 0px;
}

@media only screen and (min-width: 1260px) {
  .submit-hs-tariff-code-wrapper {
    justify-content: right;
  }
}

@media only screen and (max-width: 1259px) {
  .submit-hs-tariff-code-wrapper {
    justify-content: center;
  }

  #submit-hs-tariff-code-button {
    width: 100%;
  }
}

#submit-hs-tariff-code-text,
#submit-hs-tariff-info-text {
  color: #ffffff;
}

@media (min-width: 576px) {
  #modal-tlc {
    max-width: 765px;
  }
}

.field-length {
  display: flex;
  justify-content: right;
}

#tlc-wrapper {
  padding-top: 100px;
}

#tlc-lookup-paragraph {
  margin-bottom: 30px;
}

#tlc-lookup-add-more-details,
#tlc-lookup-country-of-origin-wrapper {
  margin-bottom: 16px;
}

#tlc-lookup-tell-us-more {
  margin-bottom: 10px;
  margin-top: 15px;
}

#tlc-lookup-dimensions-text {
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 15px;
}

#tlc-text-header {
  color: rgb(102, 101, 131);
  font-size: 20px;
}

.tlc-button-wrapper {
  margin-bottom: 20px;
}

.tlc-quantity-input {
  padding-right: 20px;
}

.tlc-quantity-btn-wrapper {
  align-items: flex-end;
  display: flex;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 8px;
}

.tlc-quantity-btn {
  font-size: 16px;
  height: 43px;
  padding-bottom: 9px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 9px;
}

.field-icon.tlc-units {
  font-size: 16px;
  top: 9px;
}

#weightOuncesTlc {
  margin-top: 11px;
}

#submit-hs-tariff-info-button {
  height: 44px !important;
  margin-top: 20px;
}

#submit-hs-tariff-info-text {
  font-size: 16px;
}

@media (min-width: 576px) {
  #modal-tlc-hs-tariff,
  #modal-tlc-prohibited {
    max-width: 700px;
  }
}

#modal-tlc-text,
#modal-tlc-hs-tariff-text {
  padding-bottom: 40px;
}

@media (min-width: 576px) {
  #modal-tlc-estimate {
    max-width: 735px;
  }
}

#tlc-table {
  margin-left: 10px;
  margin-right: 10px;
}

.table td.tlc-table-column {
  border-top: none;
}

.table .tlc-table-column-cost {
  text-align: right;
}

#tlc-summary-row {
  background-color: #f7f7f7;
  border-top: 2px solid #e71921;
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: rgb(231, 25, 33);
}

#tlc-summary-text {
  vertical-align: middle;
}

#tlc-email-header {
  margin-bottom: 10px;
  margin-top: 20px;
}

.submit-tlc-email-wrapper {
  align-items: center;
  display: flex;
  padding-top: 15px;
}

@media only screen and (max-width: 575px) {
  .submit-tlc-email-wrapper {
    padding-top: 0px;
  }

  #submit-tlc-email-button {
    width: 100%;
  }
}

#tlc-email-confirmation-text {
  color: rgb(87, 186, 149);
}

#summary-tlc-text {
  color: #333366;
}

#summary-view-tlc {
  color: #3573b1;
  text-decoration: underline;
}

/* END: TLC */

.qtip-default {
  background-color: #fff;
  border: 1px solid #b9b9b9;
}

.y-bordered {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  padding: 10px 0;
}

.text-label {
  display: inline-block;
  font-family: "Helvetica Neue LT W01_75 Bold";
  margin-right: 30px;
}

ul.list-group.bullet-list {
  margin-bottom: 1em;
  padding-left: 16px;
}

ul.list-group.bullet-list ul,
ul.list-group.bullet-list ol {
  margin: 0;
  padding-top: 10px;
}

.control-list {
  display: inline-block;
}

.control-list.hidden,
.control-list.hide,
.control-list.mobile-only,
.control-list.mobileonly,
.control-list.phone-only,
.control-list.phoneonly {
  display: none;
}

.select-a-view {
  margin-bottom: 30px;
}

.control-list ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
}

.control-list ul li {
  display: inline-block;
  list-style: none;
  margin: 0 0 0 15px;
  padding: 0 0 5px;
}

.control-list ul li:first-child {
  margin-left: 0;
}

.control-list ul li img {
  margin-top: 3px;
  vertical-align: top;
}

.control-list ul li button span {
  border-bottom: 5px solid transparent;
  font-family: "Helvetica Neue LT W01_75 Bold";
  display: inline-block;
}

.control-list ul li.active button span {
  border-color: #e71921;
}

ul.package {
  display: flex;
  flex-direction: column;
  height: 100%;
}

ul.package li:first-child {
  flex: 1 1 auto;
  margin-bottom: 32px;
}

ul.package li a {
  color: #333366;
  display: inline-block;
  font-family: "Helvetica Neue LT W01_75 Bold";
  padding: 10px 0 7px;
  text-decoration: none;
  vertical-align: middle;
}

ul.package li a span {
  color: #e71921;
  left: 5px;
  position: relative;
  top: -1px;
}

#text-view-as {
  color: #333366;
  opacity: 1;
}

.text-select-a-service {
  color: #000;
}

.select-a-service .btn {
  font-weight: normal;
}

.panel-group {
  display: none;
}

.panel-group.active {
  display: flex;
}

.select-a-view ul li {
  opacity: 0.5;
}

.select-a-view ul li.active {
  opacity: 1;
}

.panel-groups-container {
  clear: both;
  display: flex;
  flex-direction: column;
  float: left;
  margin-top: 3px;
  width: 100%;
}

.panel-groups-container:first-child {
  margin-top: 0;
}

.paneltype-view-grid .method-selection-box,
.paneltype-view-grid .method-name {
  font-size: 12px;
}

.paneltype-view-grid .method-price {
  font-size: 15px;
}

.paneltype-view-list .method-selection label input {
  float: left;
  display: inline;
  margin-top: 5px;
}

.paneltype-view-list .method-image-container {
  display: none;
}

.paneltype-view-list .method-selection label .method-selection-box {
  border: none;
  float: left;
  margin: 0;
  min-height: 0;
  padding: 0;
  width: calc(100% - 15px);
}

.paneltype-view-list .method-selection-box > .row {
  display: block;
  margin: 0;
}

.paneltype-view-list .method-selection-box > .row > .method-text-container {
  max-width: 100%;
  padding: 0;
  width: 100%;
}

.paneltype-view-list .panel-items {
  display: block;
  margin: 0;
}

.paneltype-view-list .panel-item {
  border-top: 1px solid #e5e5e5;
  clear: both;
  display: inline;
  float: left;
  max-width: 100%;
  width: 100%;
}

.paneltype-view-list .panel-item:first-child {
  border-top: none;
}

.paneltype-view-list .method-col-1 {
  float: left;
  padding-left: 5px;
  padding-right: 10px;
  width: 80%;
}

.paneltype-view-list .method-col-1 .method-desc {
  float: left;
  width: 50%;
}

.paneltype-view-list .method-col-1 .schedule-note {
  float: left;
  width: 50%;
}

.paneltype-view-list .method-price {
  color: #000;
  float: left;
  font-size: 14px;
  margin: 0;
  width: 20%;
}

.paneltype-view-list
  .method-selection
  label
  input:checked
  + .method-selection-box {
  background: 0;
  border: none;
  margin: 0;
  padding: 0;
}

.panel-items-list-header {
  border-bottom: 1px solid #e71921;
  display: none;
  padding-bottom: 10px;
}

.paneltype-view-list .panel-items-list-header {
  clear: both;
  display: block;
  float: left;
  padding-top: 20px;
  width: 100%;
}

.sorter span {
  cursor: pointer;
  display: inline-block;
  padding-right: 18px;
  position: relative;
}

.ascOrder {
  content: "";
  height: 13px;
  position: absolute;
  right: 57px;
  font-size: medium;
  top: -2px;
  width: 260px;
}

.dscOrder {
  bottom: -18px;
  height: 11px;
  position: absolute;
  right: 58px;
  width: 257px;
  font-size: medium;
}

.panel-items-list-header .method-name,
.panel-items-list-header .method-note,
.panel-items-list-header .method-price {
  color: #333366;
  font-family: "Helvetica Neue LT W01_75 Bold";
  font-size: 12px;
}

.panel-items-list-header .method-note {
  padding-left: 5px;
}

.readmore-content {
  height: 44px;
  overflow: hidden;
  transition: all 500ms ease;
}

.readmore-content.active {
  height: auto;
  overflow: visible;
}

.readmore-buttons {
  display: block;
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.headline-buttons ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.headline-buttons ul li {
  border-left: 1px solid #b9b9b9;
  display: inline;
  float: left;
  font-size: 14px;
  line-height: normal;
  margin: 0 0 0 20px;
  padding: 0 0 0 20px;
}

.headline-buttons:first-child ul li:first-child {
  border-left: none;
  margin-left: 0;
  padding-left: 0;
}

.accordion {
  display: flex;
}

.accordion-item .accordion-title {
  background: #f7f7f7;
  color: #333366;
  padding: 10px 30px 10px 10px;
  position: relative;
}

.accordion-item .accordion-title:hover {
  background-color: #333366;
  color: #f7f7f7;
  cursor: pointer;
}

.accordion-item .accordion-title:hover h2 {
  color: #f2f2f2;
}

/* .accordion-item .accordion-content {
  display: none;
} */

.accordion-item.active {
  border: 1px solid #d8d8d8;
  padding: 20px;
}

.accordion-item.inner-accordion {
  background-color: #f7f7f7;
  border: none;
}

.accordion-item.active .accordion-content {
  display: block;
}

.accordion-tabs {
  width: 100%;
  margin-right: 30px;
  max-width: 342px;
}

.accordion-tab h2 {
  font-size: 18px;
}

.drawers .accordion-tabs {
  width: 42%;
}

.drawers .accordion-tab h2 {
  font-size: 18px;
}

.accordion-tabs button {
  background: #f7f7f7;
  border-bottom: 1px solid #e3e3e3;
  border: none;
  color: #000000;
  display: block;
  margin: 0 0 1px;
  padding: 20px 30px 20px 20px;
  position: relative;
  text-align: left;
  transition: all 500ms ease;
  width: 100%;
}

.drawers .accordion-tabs button {
  border: none;
  padding: 20px 30px 20px 20px;
}

.advanced-search-container {
  background-color: #f7f7f7;
  padding: 15px;
}

.accordion-tabs button:after {
  background: transparent url(images/blue-caret.svg) no-repeat right center /
    contain;
  content: "";
  float: right;
  height: 26px;
  position: absolute;
  right: 10px;
  top: calc(50% - 13px);
  width: 14px;
}

.accordion-item .accordion-title:after {
  background: transparent url(images/blue-caret.svg) no-repeat right center /
    contain;
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  transition: transform 0.2s ease-out;
  content: "";
  float: right;
  height: 26px;
  position: absolute;
  right: 20px;
  top: calc(50% - 13px);
  width: 14px;
}

.accordion-item .accordion-title:hover::after {
  background: transparent url(images/white-caret.svg) no-repeat right center /
    contain;
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  transition: transform 0.2s ease-out;
  content: "";
  float: right;
  height: 26px;
  position: absolute;
  right: 20px;
  top: calc(50% - 13px);
  width: 14px;
}

.accordion-tabs button:hover {
  background: #d8d8d8;
}

.accordion-tabs .active button {
  background: #333366;
  color: #fff;
}

.accordion-tabs .active button h2,
.accordion-tabs .active button h3 {
  color: #fff;
}

.accordion-tabs .active button:after {
  -webkit-filter: grayscale(100%) brightness(5);
  filter: grayscale(100%) brightness(5);
}

.accordion-item.active .accordion-title:after {
  background: transparent url(images/blue-caret.svg) no-repeat center center /
    contain;
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: transform 0.2s ease-out;
}

.accordion-items {
  float: right;
  width: calc(100% - 210px);
}

/* .drawers .accordion-items {
  width: 53%;
} */

#modal-weight-exceeded {
  display: block;
  transition: none;
}

#modal-value-exceeded {
  display: block;
  transition: none;
}

#modal-weight-exceeded .modal-title,
#modal-value-exceeded .modal-title {
  width: 100%;
}

.guide-image {
  margin-bottom: 1rem;
}

.gi-outer {
  background: #ededed;
  border-radius: 50%;
  display: block;
  height: 96px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 96px;
}

.gi-inner {
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.guide-image img {
  display: block;
  margin: 0 auto;
  max-height: 60px;
  text-align: center;
}

#european-union-image {
  max-height: none;
}

.ic-item-actions ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.ic-item-actions ul li {
  list-style: none;
  margin: 10px auto;
  padding: 0;
}

.summary-row .items-row-item:nth-child(2n + 1) {
  background-color: #f7f7f7;
}

.row-locked input {
  background-color: transparent !important;
  border-color: transparent !important;
}

.row-locked .btn-edit-container,
.row-editing .btn-edit-container {
  display: block;
}

.popover-header {
  padding: 0px;
  background-color: #fff;
  border-bottom: none;
  padding-bottom: 15px;
}

.modal-trigger {
  cursor: pointer;
  text-decoration: none;
}

.modal-header {
  border: none;
  border-bottom: none;
  display: inline-block;
}

.modal-body > .container {
  padding-left: 0;
  padding-right: 0;
}

.modal-dialog {
  align-items: center;
  display: grid;
  min-height: calc(100% - (1.75rem * 2));
}

@media only screen and (max-width: 575px) {
  .modal-dialog {
    min-height: calc(100% - (7.75rem * 2)) !important;
  }
}

.close {
  opacity: inherit;
}

.popover .close:after {
  background-size: 13px 13px;
}

.close:after {
  content: "";
  background-image: url(./images/close_x.svg);
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: 0px 1px;
  width: 15px;
  height: 16px;
}

.ab-section {
  margin-top: 20px;
}

.ab-section:first-child {
  margin-top: 0;
}

.ab-section a {
  text-decoration: none;
}

.manage-ab-link-section {
  border-top: 1px solid #f1f1f1;
  padding-top: 20px;
}

#search {
  float: right;
}

#receiptContainer {
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
  margin: 40px auto;
}

#receiptContainer .col {
  padding-bottom: 20px;
  padding-top: 20px;
}

#receiptContainer > .row .col:first-of-type {
  border-right: 1px solid #000000;
}

#receiptContainer ol {
  counter-reset: list;
}

#receiptContainer ol > li {
  list-style: none;
  position: relative;
}

#receiptContainer ol > li:before {
  content: counter(list) ")";
  counter-increment: list;
  left: -40px;
  padding-right: 10px;
  position: absolute;
  text-align: right;
  width: 40px;
}

ul.address-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

ul.address-list label {
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;
  padding-bottom: 5px;
  padding-top: 20px;
}

ul.address-list label:first-of-type {
  padding-top: 0;
}

ul.address-list li {
  padding-bottom: 0;
  padding-left: 40px;
  padding-top: 0;
}

.barcode-container {
  text-align: center;
}

.barcode {
  color: #000000;
  font-family: "Libre Barcode 128 Text", cursive;
  font-size: 3.4375rem;
}

.integral#search {
  border-radius: 3px;
  border: 1px solid #999;
  width: 100%;
}

.search-input {
  float: left;
  padding-right: 10px;
  width: 70%;
}

.integral .search-input {
  width: calc(100% - 40px);
}

#search input#s {
  color: #555555;
  float: left;
  vertical-align: middle;
  width: 100%;
}

.integral#search input#s {
  background: 0;
  border: none;
  padding: 10px;
}

.search-submit {
  float: right;
  width: 30%;
}

.integral .search-submit {
  margin-top: 8px;
  width: 40px;
}

#search input#searchsubmit,
#search button {
  color: #555555;
  float: left;
  vertical-align: middle;
  width: 100%;
}

#search button i {
  display: none;
}

.integral#search input#searchsubmit,
.integral#search button {
  background: 0;
  border: none;
}

.integral#search button span {
  display: none;
}

.integral#search button i {
  display: inline;
}

.ab-category-filter ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.ab-category-filter ul li {
  display: block;
  font-size: 14px;
  line-height: normal;
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
}

.ab-category-filter ul li ul {
  padding-left: 15px;
}

.ab-category-filter ul li a {
  color: #000;
}

.ab-category-filter ul .active > a {
  font-family: "Helvetica Neue LT W01_75 Bold";
}

.ab-right {
  margin-top: -62px;
}

.ab-letter-search ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.ab-letter-search ul li {
  display: inline-block;
  font-size: 14px;
  line-height: normal;
  list-style: none;
  margin: 0 0 0 15px;
  padding: 0;
}

.ab-letter-search ul li:first-child {
  margin-left: 0;
}

.ab-letter-search ul li a {
  border-bottom: 3px solid transparent;
  display: inline-block;
  font-family: "Helvetica Neue LT W01_75 Bold";
  font-size: 20px;
}

.ab-letter-search ul li.active a {
  border-color: #e71921;
}

.contact-row-header {
  border-bottom: 3px solid #e71921;
  color: #000;
  font-family: "Helvetica Neue LT W01_75 Bold";
  margin-left: 0;
  margin-right: 0;
}

.ab-contact-data-row {
  border-top: 1px solid #f1f1f1;
  margin-left: 0;
  margin-right: 0;
}

.ab-contact-data-row:first-child {
  border-top: none;
}

.ab-contact-data-row.active {
  background: #f7f7f7;
}

.cr-cell {
  padding: 10px;
}

.ab-contact-city,
.ab-contact-state,
.ab-contact-postal-code {
  display: inline;
}

.btn-address {
  display: none;
}

.text-results-per-page {
  display: inline-block;
  margin-right: 10px;
}

.results-per-page-selector {
  display: inline-block;
  margin-right: 10px;
  width: 80px;
}

.btn-back {
  text-decoration: none;
}

.btn-prev {
  background: transparent url(images/btn-prev.svg) no-repeat left top / contain;
  border: none;
  display: block;
  font-size: 0;
  height: 26px;
  line-height: 0;
  overflow: hidden;
  text-indent: -9999px;
  width: 14px;
}

.btn-prev.disabled {
  background-image: url(images/btn-prev-disabled.svg);
  cursor: default;
}

.btn-next {
  background: transparent url(images/btn-next.svg) no-repeat left top / contain;
  border: none;
  display: block;
  font-size: 0;
  height: 26px;
  line-height: 0;
  overflow: hidden;
  text-indent: -9999px;
  width: 14px;
}

.btn-next.disabled {
  background-image: url(images/btn-next-disabled.svg);
  cursor: default;
}

.results-page-button {
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
}

.results-pages {
  display: inline-block;
  vertical-align: middle;
}

.results-pages ol {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.results-pages ol li {
  color: #333366;
  display: inline-block;
  list-style: none;
  margin: 5px;
  padding: 0;
}

.results-pages ol li button {
  background: #fff;
  border: none;
  color: #3573b1;
  display: inline-block;
  height: 30px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  width: 30px;
}

.results-pages ol li.active button {
  background: #333366;
  border-radius: 50%;
  color: #fff;
  cursor: default;
}

.modal-header .close {
  z-index: 2;
}

.custom-icon {
  display: inline-block;
}

.ci-arrow-up {
  background: transparent url(images/ci-arrow-up.svg) no-repeat left top /
    contain;
  height: 24px;
  width: 11px;
}

.ci-arrow-down {
  background: transparent url(images/ci-arrow-down.svg) no-repeat left top /
    contain;
  height: 24px;
  width: 11px;
}

.ci-sort {
  background: transparent url(images/ci-sort.svg) no-repeat left top / contain;
  height: 24px;
  width: 24px;
}

.ci-sort-down {
  background: url(images/ci-arrow-down.svg) no-repeat left top / contain,
    url(images/ci-sort.svg) no-repeat right -4px top -3px / contain;
  height: 24px;
  margin-left: 5px;
  width: 27px;
}

.ci-sort-up {
  background: url(images/ci-arrow-up.svg) no-repeat left top / contain,
    url(images/ci-sort.svg) no-repeat right -4px top 3px / contain;
  height: 24px;
  margin-left: 5px;
  width: 27px;
}

.history-sort-row,
.history-sort-placeholder {
  margin-top: 15px;
}

.history-sort-row .row.align-items-center {
  background: transparent url(images/drag-handle.svg) no-repeat 4px center;
}

.history-sort-row:first-child {
  margin-top: 0;
}

.history-sort-row .form-group {
  margin-bottom: 0;
}

.history-sort-row label {
  align-items: center;
  display: flex;
}

select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("images/blue-caret-down.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 20px 10px;
  border-radius: 4px;
  border: 1px solid #333366;
  color: #333366;
  display: block;
  padding: 11px 5px;
  text-indent: 1px;
  text-overflow: "";
  width: 100%;
}

select.form-control::-ms-expand {
  display: none;
}

select.form-control:active,
select.form-control:focus {
  outline: none !important;
}

select.form-control option:hover {
  background: #333366 !important;
  color: #fff !important;
}

.dragged {
  opacity: 0.5;
  position: absolute;
  z-index: 2000;
}

.history-sort-placeholder.placeholder {
  background: #f7f7f7;
  display: block;
  min-height: 43px;
  position: relative;
  width: 100%;
}

.history-sort-placeholder.placeholder:before {
  position: absolute;
}

.history-sort-row.dragged .sort-label-row {
  display: none;
}

#history-output-table {
  margin-top: 60px;

  overflow: auto;
}

.usps-table-row {
  border-top: 1px solid #d8d8d8;
}

.usps-table-header {
  border-top: none;
  font-family: "Helvetica Neue LT W01_75 Bold";
}

.table thead tr.usps-table-header th {
  border-bottom: 2px solid #e71921;
  border-top: none;
  border-top: none;
  font-family: "Helvetica Neue LT W01_75 Bold";
  white-space: nowrap;
}

.btn-reverse {
  float: right;
}

.hidden-col {
  display: none !important;
}

.usps-table-col-date-and-row-check .form-group {
  display: inline-block;
  padding-right: 0px !important;
}

.usps-table-col-date-and-row-check .usps-table-col-date-inline {
  display: inline;
}

.usps-table-footer {
  padding: 10px 0;
}

.buttons-group {
  display: inline-block;
}

.buttons-group.centered {
  display: block;
  text-align: center;
}

.buttons-group:first-child {
  margin-left: 0;
}

.buttons-group ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.buttons-group ul li {
  display: inline-block;
  list-style: none;
  margin: 5px;
  padding: 0;
}

.buttons-group ul li:first-child {
  margin-left: 0;
}

ul.sidebar-links {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.sidebar-links li {
  display: block;
  font-size: 14px;
  line-height: normal;
  list-style: none;
  margin: 0;
  padding: 5px 0;
}

#preferences-package-type .usps-table-header {
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
}

#preferences-package-type .usps-table-body {
  border-top: none;
  border: 1px solid #f3f3f3;
  height: 500px;
  overflow: auto;
}

.line-data {
  color: #000;
}

.sidebar-box {
  background: #f7f7f7;
  padding: 30px;
}

#submit-btn-container {
  display: none;
}
.active#submit-btn-container {
  display: inline-block;
}

.autocomplete-no-suggestion {
  border: none !important;
  color: #ff0000;
  display: block !important;
}
#selection-ajax {
  color: #ff0000;
}

.form-group-alt label {
  color: #000;
}

.prohib-error-ln-1 {
  display: block;
}
.prohib-error-ln-2 {
  display: block;
  color: #000;
}

@media only screen and (min-width: 1260px) {
  .items-row-item.row-locked .ic-item-actions ul li {
    margin-bottom: 0;
    margin-top: 0;
  }

  .input-qty {
    text-align: right;
  }

  ul.title-links li {
    margin: 0 0 0 20px !important;
  }

  ul.title-links li:before {
    padding-right: 20px !important;
  }

  .results-page-selector-wrapper {
    flex: 0 0 38%;
    max-width: 38%;
  }
}

@media only screen and (max-width: 1259px) {
  .form-button-checkbox {
    clear: both;
  }

  .form-button-checkbox + .form-button-container {
    clear: both;
  }

  .items-row-header {
    display: none;
  }

  .ic-item-quantity:before {
    content: "Total Qty: ";
    text-align: left;
  }

  .ic-item-weight:before {
    content: "Total Weight: ";
    text-align: left;
  }

  .ic-item-value:before {
    content: "Total Item Value: ";
    text-align: left;
  }

  .ic-item-description:before {
    content: "Description: ";
    display: block;
    text-align: left;
  }

  .ic-item-actions ul {
    clear: both;
    width: 100%;
  }

  .ic-item-actions ul li {
    clear: both;
    margin: 15px auto auto;
    width: 100%;
  }

  /* .ic-item-actions ul li:first-child {
    margin-top: 0;
  } */

  .ic-item-actions ul li button {
    border-radius: 0.25rem;
    border: 1px solid #336 !important;
    color: #336;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 0.65rem 1.3rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
  }

  .ic-item-number-id {
    text-align: left;
  }

  .ic-item-number-id:before {
    content: "#";
  }

  .item-cell {
    text-align: left;
  }

  .row-locked .weight-row {
    display: inline-block;
    margin: 0;
  }

  .row-locked .weight-col {
    display: inline-block;
    max-width: 100%;
    width: auto;
  }

  .row-locked .weight-col .field-wrapper {
    display: inline-block;
  }

  .row-locked .weight-col .field-wrapper .form-control {
    display: inline-block;
    max-width: 55px;
    width: auto;
  }

  .items-row-item .item-cell.ic-item-number {
    padding: 2px 0;
  }
}

@media only screen and (min-width: 992px) {
  .modal-lg {
    max-width: 640px;
  }

  .desktop-textleft {
    text-align: left;
  }

  .desktop-textcenter {
    text-align: center;
  }

  .desktop-textright {
    text-align: right;
  }

  ul.title-links {
    float: right;
    margin: 0;
    padding: 0;
  }

  ul.title-links li {
    display: inline;
    font-size: 14px;
    line-height: normal;
    margin: 0 0 0 10px;
    padding: 0;
  }

  ul.title-links li:before {
    color: #d8d8d8;
    content: "/";
    display: inline-block;
    font-size: 24px;
    padding-right: 10px;
    vertical-align: middle;
  }

  ul.title-links li:first-child:before {
    display: none;
  }

  ul.title-links li:first-child {
    margin-left: 0;
  }

  ul.title-links li a {
    border-bottom: 4px solid transparent;
    color: #333366;
    display: inline-block;
    font-family: "Helvetica Neue LT W01_75 Bold";
    padding: 10px 0 7px;
    text-decoration: none;
    vertical-align: middle;
  }

  ul.title-links li a span {
    color: #e71921;
  }

  ul.title-links li.current a {
    border-color: #e71921;
  }

  .has-buttons {
    float: left;
    margin-bottom: 0;
  }

  .headline-buttons {
    float: left;
    margin-top: 5px;
  }

  .title-links-toggle {
    display: none;
  }

  .history-columns-col {
    border-right: 1px solid #f0f0f0;
  }

  .history-sort-col {
    padding-left: 30px;
  }

  .wem-right,
  .lg-border-left {
    border-left: 1px solid #707070;
  }

  .items-row-item.row-locked .items-row-inner-commercial,
  .summary-row .items-row-inner-commercial {
    border-top: 1px solid #d8d8d8;
  }
}

@media only screen and (max-width: 991px) {
  .table thead tr.usps-table-header th {
    padding-right: 20px;
    position: relative;
  }

  .btn-reverse {
    float: none;
    position: absolute;
    right: 0;
  }

  .modal-lg,
  .modal-xl {
    max-width: 640px;
  }

  .mobile-only,
  .mobileonly {
    display: block;
  }

  .mobilehide,
  .mobile-hide {
    display: none !important;
  }

  .method-selection label .method-selection-box {
    padding: 5px;
  }

  ul.title-links {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.title-links.active {
    display: block;
    float: none;
    margin-top: 15px;
  }

  ul.title-links li {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.title-links li a {
    display: block;
    font-size: 12px;
    padding: 8px;
  }

  ul.title-links li a:hover {
    background: #ededed;
  }

  .headline-buttons ul {
    clear: both;
    float: left;
    width: 100%;
  }

  .headline-buttons ul li:first-child {
    border: none;
    margin-left: 0;
    padding-left: 0;
  }

  .title-links-toggle {
    /* background: transparent url(images/down-carrat.svg) no-repeat left top / contain; */
    background: transparent url(images/blue-caret.svg) no-repeat left top /
      contain;
    border: none;
    clear: both;
    float: right;
    height: 9px;
    margin: -15px 0 0;
    padding: 0;
    transition: all 0.2s cubic-bezier(0, 0.75, 0.05, 0.98);
    width: 15px;
  }

  .title-links-toggle.active {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .history-sort-col {
    margin-top: 30px;
  }

  .ab-right {
    margin-top: 30px;
  }

  .btn-address {
    width: 100%;
  }

  .results-page-selector {
    text-align: left;
  }

  .wem-right {
    border-top: 1px solid #707070;
  }

  .items-row-item.row-locked .items-row-inner-commercial {
    border-bottom: 1px solid #d8d8d8;
  }
}

@media only screen and (min-width: 768px) {
  .md-border-left {
    border-left: 1px solid #707070;
  }

  .method-selection label .method-selection-box {
    border-bottom: 1px solid #e5e5e5;
    min-height: 175px;
  }
}

@media only screen and (min-width: 767px) {
  .advanced-search-button-container {
    text-align: center;
  }

  .phone-only,
  .phoneonly {
    display: block;
  }

  ul.title-links {
    float: right;
  }

  .accordion-tabs {
    display: none;
  }

  .accordion-item .accordion-title {
    padding-left: 20px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
  }

  .accordion-title .drawer-desc {
    display: none;
  }

  .accordion-title h2 {
    font-size: 18px;
    margin: auto 0;
  }

  .accordion-item .accordion-title {
    border-bottom: 1px solid #d8d8d8;
    min-height: 63px;
  }

  .accordion-item .accordion-title:after {
    height: 63px;
    top: 0;
    width: 11px;
  }

  .accordion-item.active .accordion-title .drawer-desc {
    display: block;
  }

  .accordion-items,
  .drawers .accordion-items {
    width: 100%;
  }

  .accordion-item.active .accordion-item {
    border-top: 1px solid #d8d8d8;
  }

  .accordion-item .accordion-content {
    background: white;
    border: 1px solid #336;
  }

  .accordion-item .accordion-content {
    padding: 10px;
  }

  .history-sort-row .row.align-items-center {
    background-position: 4px 15px;
  }

  .summary-item {
    margin: 15px 0;
  }

  .text-label {
    display: block;
    margin-bottom: 5px;
  }

  .mobile-list-selector {
    display: block;
  }

  .mobile-list-selector ul {
    background-clip: padding-box;
    background-color: #fff;
    /* background-image: url("images/accordion-carrat.svg"); */
    background-image: url(images/blue-caret.svg);
    background-position: right 10px top 17px;
    background-repeat: no-repeat;
    background-size: 20px 10px;
    border-radius: 3px;
    color: #333366;
    display: block;
    font-size: 14px;
    font-weight: 400;
    height: auto;
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
  }

  .mobile-list-selector ul li {
    display: none;
    margin: 0;
    padding: 0;
  }

  .mobile-list-selector.active ul li,
  .mobile-list-selector ul li.active {
    display: block;
  }

  .mobile-list-selector ul li button {
    border-radius: 0;
    display: block;
    padding: 10px;
    width: 100%;
  }

  .mobile-list-selector.active ul li.active button {
    background-color: #f7f7f7;
    color: #333366;
  }

  .mobile-list-selector ul li:hover button {
    border-bottom: 3px solid red;
  }

  .mobile-list-selector ul li button span {
    border: none;
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;
  }
}

@media only screen and (min-width: 576px) {
  .form-button-container.form-button-container-left {
    float: left;
    margin-right: 30px;
  }

  .form-button-container.form-button-container-left + .form-button-container {
    margin-right: 0;
  }

  .summary-row {
    border-top: 1px solid #d8d8d8;
    margin-top: 30px;
    padding-top: 30px;
  }

  .summary-row:first-child {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  #receiptContainer > .row .col:first-of-type {
    border-right: none;
  }

  #receiptContainer > .row .col:first-of-type::after {
    border-bottom: 1px solid #000000;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: calc(100% - 30px);
  }

  .panel-groups-container:first-child {
    margin-left: -15px;
    width: 100vw;
    margin-right: -15px;
  }

  .barcode {
    font-size: 2rem;
  }

  .progress-title {
    display: block;
  }

  .progress-title br {
    display: none;
  }

  .progress-circle:before,
  .progress-circle:after {
    width: 15px;
  }

  .form-button-container {
    display: block;
    float: none;
    margin: 0;
  }

  .form-button-container:not(:first-child) {
    margin: 15px 0 0;
  }

  .form-button-container button {
    text-align: center;
    width: 100%;
  }

  .active#submit-btn-container {
    display: block;
  }

  .method-name {
    font-size: 16px;
  }

  .paneltype-view-list .method-col-1 .method-desc,
  .paneltype-view-list .method-col-1 .schedule-note {
    float: none;
    padding: 0;
    width: auto;
  }

  .paneltype-view-list .method-col-1 {
    float: left;
    width: 78%;
  }

  .contact-row-header {
    display: none;
  }

  .cr-cell-company:empty {
    display: none;
  }

  .buttons-group,
  .buttons-group ul {
    display: block;
  }

  .buttons-group ul li {
    display: block;
    margin: 15px 0 0;
  }

  .buttons-group ul li:first-child {
    margin-top: 0;
  }

  .buttons-group ul li button {
    width: 100%;
  }

  .btn-search,
  .btn-update-history-cols {
    display: block;
    width: 100%;
  }

  .radio-button-inline {
    clear: both;
    display: inline;
    float: left;
    margin: 15px 0 0;
    width: 100%;
  }

  .radio-button-inline:first-child {
    margin-top: 0;
  }

  .btn-back {
    width: 100%;
  }

  .usps-table-body {
    overflow-x: auto;
  }

  .panel-items-list-header .schedule-note {
    display: none;
  }

  #tracking-label-number {
    display: block;
    font-size: 18px;
  }

  .summary-item {
    border-top: 1px solid #d8d8d8;
    margin: 0;
    padding: 15px;
  }

  .items-in-your-package {
    border-top: 1px solid #d8d8d8;
    margin-top: 30px;
    padding-top: 30px;
  }

  .items-row-item.row-locked .item-cell.ic-item-description {
    -moz-order: 1;
    -ms-flex: 0 0 83.333333%;
    -webkit-order: 1;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    order: 1;
    padding: 2px 15px;
  }

  .items-row-item.row-locked .item-cell.ic-item-actions {
    -moz-order: 2;
    -ms-flex: 0 0 16.666667%;
    -webkit-order: 2;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    order: 2;
  }

  .items-row-item.row-locked .item-cell.ic-item-quantity {
    -moz-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .items-row-item.row-locked .item-cell.ic-item-value {
    -moz-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .items-row-item.row-locked .item-cell.ic-item-weight {
    -moz-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .items-row-item.row-locked .item-cell.ic-xs-full {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 2px 15px;
  }

  .items-row-item.row-locked .item-cell .form-control,
  .items-row-item.row-locked .item-cell .field-wrapper {
    display: inline-block;
    width: auto;
  }

  .items-row-item.row-locked .item-cell.ic-item-actions {
    padding: 2px 0;
  }

  .line-label,
  .line-data {
    display: inline;
  }

  .row-locked .weight-col {
    padding-left: 0;
  }

  .row-locked .ic-item-quantity:before,
  .row-locked .ic-item-weight:before,
  .row-locked .ic-item-value:before {
    float: left;
    min-width: 50px;
  }

  .row-locked .ic-item-actions ul li {
    clear: none;
    margin: 2px 0 2px 10px;
    width: auto;
  }

  .row-locked .ic-item-actions ul li button {
    border: none !important;
    padding: 0;
    width: auto;
  }

  .row-locked .btn-edit-item:before {
    margin: 0;
  }
}

/* FAQ / Prohibitions and Restrictions */
.faq-section span.expand,
.visible-expand span.expand {
  margin-right: 45px;
  display: block;
}

.faq-section {
  padding: 0 10px;
}

.faq-unit:last-of-type,
.expand-unit:last-of-type {
  border-bottom: 1px solid #d8d8d8;
}

.faq-section h2,
.visible-expand h2 {
  margin-bottom: 30px;
}

.accordion-header.expand-all-header {
  display: inline;
}

.accordion-header {
  font-size: 18px;
  line-height: 63px;
  position: relative;
}

.accordion-header a {
  text-decoration: none;
  color: #333366;
  position: relative;
  display: inline-block;
}

.accordion-header a:hover,
.accordion-header a:focus {
  color: #333366;
}

.accordion-header::after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 0;
  background-size: 20px 20px;
  top: 35%;
  width: 20px;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 20px;
  background-image: url(images/blue-caret.svg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: transform 0.2s ease-out;
}

.accordion-header.active::after,
.active > .accordion-header::after {
  background-image: url(images/blue-caret.svg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: transform 0.2s ease-out;
}

.accordion-header.expand-all-header::after {
  background-size: 15px;
  height: 67px;
  left: 87px;
  position: absolute;
  top: 2%;
  width: 17px;
  z-index: 0;
}

.accordion-header.expand-all-header a {
  padding-right: 45px;
  position: relative;
  z-index: 1;
}

.accordion-header {
  padding-left: 0;
  padding-right: 0;
}

.accordion-header:not(.expand-all-header) {
  border-top: 1px solid #d8d8d8;
}

.accordion-header.active,
.accordion-header:hover {
  color: #000000;
}

.accordion-header .drawer {
  display: none;
}

.accordion-header.active .drawer {
  display: block;
}

.accordion-header.active .drawer p {
  display: block;
  color: #000000;
  padding-right: 10px;
}

span.expand {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: #333366;
  float: right;
  top: 10px;
  cursor: pointer;
  position: relative;
  padding: 30px 0;
  white-space: nowrap;
}

span.expand:after {
  content: "";
  display: inline-block;
  background-image: url(images/blue-caret.svg);
  background-repeat: no-repeat;
  margin-left: -25px;
  position: relative;
  height: 20px;
  width: 20px;
  left: 30px;
  top: 10px;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: transform 0.2s ease-out;
}

span.expand.active:after {
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 0;
  transition: transform 0.2s ease-out;
}

.visible-expand div.expand-unit {
  padding-left: 0;
}

.visible-expand div.expand-unit > p {
  padding-left: 0;
}

.visible-expand {
  margin-bottom: 50px;
}

.visible-expand div.expand-unit .accordion-header {
  padding-left: 0;
  padding-right: 30%;
}

.visible-expand div.expand-unit > p {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 65%;
}

.visible-expand .expand-unit .drawer {
  width: 70%;
  padding-left: 15px;
}

.visible-expand .expand-unit p,
.visible-expand .expand-unit.active .drawer p {
  color: #000000;
}

.faq-section.full-width .faq-unit .accordion-header a {
  width: 90%;
}

.expand-unit.active .expand-more {
  display: none;
}

.expand-unit .expand-less {
  display: none;
}

.expand-unit.active .expand-less {
  display: inline-block;
}

.expand-unit {
  padding-bottom: 30px;
}

.faq-unit .accordion-header {
  cursor: pointer;
}

.faq-unit:last-of-type,
.expand-unit:last-of-type {
  border-bottom: 0;
}

.faq-section h2,
.visible-expand h2 {
  margin-bottom: 30px;
}

.accordion-header {
  font-size: 18px;
  line-height: 63px;
  margin-bottom: 0;
  position: relative;
}

.expand-unit .accordion-header {
  padding-left: 30px;
}

.accordion-header.expand-all-header {
  margin-top: -20px;
}

.faq-unit .drawer,
.expand-unit .drawer {
  display: none;
}

.faq-unit.active .drawer,
.expand-unit.active .drawer {
  display: block;
  padding-bottom: 20px;
}

.faq-unit.active .drawer p,
.expand-unit.active .drawer p {
  display: block;
  color: #000000;
  padding-right: 10px;
}

span.expand {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: #333366;
  float: right;
  top: 10px;
  cursor: pointer;
  position: relative;
  padding: 30px 0;
  white-space: nowrap;
}

span.expand:after {
  content: "";
  display: inline-block;
  background-image: url(images/blue-caret.svg);
  background-repeat: no-repeat;
  margin-left: -25px;
  position: relative;
  height: 20px;
  width: 20px;
  left: 30px;
  top: 10px;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: transform 0.2s ease-out;
}

span.expand.active:after {
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 0;
  transition: transform 0.2s ease-out;
}

.visible-expand h2 {
  white-space: normal;
  display: inline-block;
}

.visible-expand div.expand-unit {
  padding-left: 0;
}

.visible-expand div.expand-unit > p {
  padding-left: 0;
}

.visible-expand {
  margin-bottom: 50px;
}

.visible-expand div.expand-unit .accordion-header {
  padding-left: 0;
  padding-right: 30%;
}

.visible-expand div.expand-unit > p {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 65%;
}

.visible-expand .expand-unit .drawer {
  width: 70%;
  padding-left: 15px;
}

.visible-expand .expand-unit p,
.visible-expand .expand-unit.active .drawer p {
  color: #000000;
}

.expand-unit.active .expand-more {
  display: none;
}

.expand-unit .expand-less {
  display: none;
}

.expand-unit.active .expand-less {
  display: inline-block;
}

.expand-unit {
  padding-bottom: 30px;
}

@media (max-width: 776px) {
  .visible-expand div.expand-unit > p {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 95%;
  }
  .visible-expand .expand-unit .drawer {
    width: 95%;
    padding-left: 15px;
  }
}

@media (max-width: 992px) {
  /* FAQ SECTION/EXPAND */
  .faq-unit {
    padding-left: 0px;
    padding-right: 0px;
  }

  .visible-expand .expand-unit .drawer {
    width: 100%;
  }

  .drawer .custom-container-2 p {
    margin: 0;
  }

  .drawer .custom-container-2.last p {
    margin-bottom: 20px;
  }
}

@media (max-width: 776px) {
  .expand {
    font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
  }

  .faq-section a.expand:hover,
  .faq-section a.expand:focus {
    color: #333366;
  }

  /* .accordion-item {
    margin-left: -15px;
    margin-right: -15px;
  } */

  .accordion-item {
    width: 100%;
  }

  .accordion-items {
    width: 100%;
  }

  .accordion-item.active {
    border-top: none;
    padding: 0;
  }

  .accordion-item:first-of-type,
  .accordion-item:first-of-type.active {
    border-top: 1px solid #d8d8d8;
  }

  .accordion-item.active .accordion-title {
    border-bottom: none;
  }

  /* .accordion-item:not(.active) .accordion-title p {
    display: none;
  } */

  .accordion-item.active .accordion-title p {
    color: #000000;
    margin-top: 10px;
    position: relative;
    padding-bottom: 10px;
  }

  .accordion-item.active .accordion-title p:last-of-type::after {
    border-bottom: 1px solid #d8d8d8;
    content: "";
    display: block;
    height: 1px;
    bottom: 0;
    left: 0;
    position: absolute;
    right: -25px;
  }

  /* .expand:after {
    content: "";
    display: inline-block;
    background-image: url(images/blue-caret.svg);
    background-repeat: no-repeat;
    margin-left: -25px;
    position: relative;
    height: 20px;
    width: 20px;
    left: 30px;
    top: 10px;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: transform 0.2s ease-out;
  } */

  .expand.active:after {
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    top: 0;
    transition: transform 0.2s ease-out;
  }

  .faq-section h2.header-2,
  .visible-expand h2.header-2 {
    margin-bottom: 30px;
  }

  .faq-section
    .row:nth-of-type(2)
    > .col-md-6:nth-of-type(1)
    .col-md-12.col-sm-12:nth-of-type(6)
    .faq-unit,
  .faq-section
    .row:nth-of-type(2)
    > .col-md-6:nth-of-type(2)
    .col-md-12.col-sm-12:nth-of-type(5)
    .faq-unit {
    border-bottom: 1px solid #d8d8d8;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*
 * Flex Classes
 *
 * Flex Container
 */

.flex {
  display: flex;
}

.flex-direction--row {
  flex-direction: row;
}

.flex-direction--row-reverse {
  flex-direction: row-reverse;
}

.flex-direction--column {
  flex-direction: column;
}

.flex-direction--column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap--nowrap {
  flex-wrap: nowrap;
}

.flex-wrap--wrap {
  flex-wrap: wrap;
}

.flex-wrap--wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* Justify Content */
.flex-jc--flex-start {
  justify-content: flex-start;
}

.flex-jc--flex-end {
  justify-content: flex-end;
}

.flex-jc--center {
  justify-content: center;
}

.flex-jc--space-between {
  justify-content: space-between;
}

.flex-jc--space-around {
  justify-content: space-around;
}

.flex-jc--space-evenly {
  justify-content: space-evenly;
}

/* Align Items */
.flex-ai--flex-start {
  align-items: flex-start;
}

.flex-ai--flex-end {
  align-items: flex-end;
}

.flex-ai--center {
  align-items: center;
}

.flex-ai--stretch {
  align-items: stretch;
}

.flex-ai--baseline {
  align-items: baseline;
}

/* Align Content */
.flex-ac--flex-start {
  align-content: flex-start;
}

.flex-ac--flex-end {
  align-content: flex-end;
}

.flex-ac--center {
  align-content: center;
}

.flex-ac--stretch {
  align-content: stretch;
}

.flex-ac--space-between {
  align-content: space-between;
}

.flex-ac--space-around {
  align-content: space-around;
}

/*
 * Flex Items
 */

/* Align Self */
.item-as--auto {
  align-self: auto;
}

.item-as--flex-start {
  align-self: flex-start;
}

.item-as--flex-end {
  align-self: flex-end;
}

.item-as--center {
  align-self: center;
}

.item-as--baseline {
  align-self: baseline;
}

.item-as--stretch {
  align-self: stretch;
}

/* Additional Classes */
.flex-fill-space {
  flex: 1 0 auto;
}

/* Modal bug fix for when closed */
/* .fade:not(.show) {
  display: none;
} */

.iframePdf {
  background: transparent;
  border: 0;
  height: 0px;
  outline: 0;
  width: 1px;
  font-size: 0;
  line-height: 0;
}

/* Progress Bar */
#progress-bar-background {
  background-color: #ddd;
  margin-top: 50px;
  transform: skew(-20deg);
  width: 100%;
}

#progress-bar {
  color: #336;
  display: inline-block;
  opacity: 1;
  overflow: hidden;
  padding: 0.1em 0 2em;
  position: relative;
}

#progress-bar:after {
  background-color: #336;
  content: "";
  height: 100%;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 100%;
}

#progress-bar-text {
  color: #333366;
  font-weight: bold;
}
/* START MODAL ADDRESS BOOK */

.pagination-container {
  display: inline-block;
  text-align: center;
}

.inactive {
  cursor: context-menu;
}

button.header-tabs {
  font-size: 12px;
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}
.header-tabs.active,
.tab-link.active {
  border-bottom: 4px solid #e71921;
  padding-bottom: 1px;
  pointer-events: none;
}

button.header-tabs.inactive {
  color: #d8d8d8;
  display: none;
  cursor: context-menu;
}

#name-header,
#company-header,
#location-header {
  color: #333366;
  font-size: 16px;
}

.groups-list .contact-group-header {
  padding-top: 20px;
}

.contact-group-header p {
  color: #333366;
  font-size: 20px;
  padding-left: 20px;
}

.contact-group-header p.active {
  padding-left: 16px;
  border-left: 4px solid #e71921;
}

.contact-groups {
  display: block;
}

.contact-groups div.row {
  padding-top: 10px;
}

.contact-groups .contact-group-name button {
  padding-left: 19px;
  font-size: 15px;
  color: black;
}

.contact-groups .contact-group-name.active button {
  padding-left: 15px;
  border-left: 4px solid #e71921;
}

.contact-sub-group.contact-group-name {
  padding-top: 10px;
  padding-left: 40px;
}

.ab-batch-message {
  padding-top: 5px;
}

#modal-address-book-table td,
#modal-address-book-table th {
  border-top: 1px solid #999999;
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
}

#modal-address-book-table > thead > tr > th {
  vertical-align: top;
  border-bottom: none;
  font-size: 16px;
  color: #333366;
}

#modal-address-book-table thead {
  border-bottom: 2px solid #e71921;
  height: 50px;
}

#modal-address-book-table th {
  text-align: left;
}

#modal-address-book-table td {
  font-size: 13px;
}

#modal-address-book-table tr:first-child th,
#modal-address-book-table tr:first-child td {
  border-top: none;
}

#modal-address-book-table tbody tr:last-child {
  border-bottom: 1px solid #999999;
}

#modal-address-book-table tr {
  width: 100%;
  display: inline-table;
  table-layout: fixed;
  height: 40px;
}

#modal-address-book-table tbody {
  overflow-y: scroll;
  max-height: 351px;
  min-height: 60px;
  display: block;
}

#modal-address-book-table tbody::-webkit-scrollbar {
  width: 10px;
}

#modal-address-book-table tbody::-webkit-scrollbar-thumb {
  background: #888;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.results-label {
  color: #333366;
  font-size: 16px;
  padding-right: 15px;
}

.results-dropdown-container select.form-control {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  background: transparent url("images/blue-caret-down.svg") no-repeat;
  background-position: 80% 16px;
  background-size: 20px;
  cursor: pointer;
  color: #333366;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 100px;
}

.results-dropdown-container select.form-control {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.nav.nav-stacked {
  padding-left: 10px;
  display: inline-block;
  min-width: 90px;
}

.radio-button:checked:after {
  display: inline-block;
}

.dropdown-menu {
  min-width: 80px;
}

.results-dropdown-container {
  display: inline-flex;
  align-items: center;
}

#results-amount-address-book {
  padding-right: 40px;
}

#mobile-contact-select button:active,
#results-amount-address-book:active,
#service-type-dropdown-usa-id:active,
#service-type-dropdown-int-id:active {
  color: #333366;
  background-color: #fff;
  border-color: #333366;
  font-size: 15px;
}

.results-amount {
  padding-right: 40px;
}

#address-book .btn-primary {
  min-width: 175px;
}

#address-book-search-input {
  border-right: none;
}

.input-group-btn .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ab-search-results-text {
  color: #333366;
}

#modal-address-book-table thead {
  display: block;
  padding-right: 25px;
}

@media only screen and (max-width: 767px) {
  #modal-address-book-table {
    margin-top: 15px;
  }

  #modal-address-book-table tbody {
    max-height: 274px;
  }

  #mobile-contact-select,
  .input-group .form-control {
    font-size: 14px;
  }

  #name-header,
  #company-header,
  #location-header {
    font-size: 14px;
  }

  #name-header,
  #company-header,
  #location-header {
    padding-bottom: 5px;
  }

  #modal-address-book-table tbody tr {
    height: 55px;
    border-bottom: 1px solid #999999;
  }

  .results-dropdown-container {
    justify-content: center;
  }

  .pagination-container {
    padding-top: 20px;
  }

  .pagination-container .results-pagination li {
    display: inline-block;
  }

  #modal-address-book-table tbody th,
  #modal-address-book-table thead th:first-child {
    display: table-cell;
    width: 10%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #modal-address-book-table {
    border: 1px solid #999999;
  }

  #modal-address-book-table thead {
    padding-right: 10px;
  }

  #modal-address-book-table thead tr {
    height: auto;
  }

  #modal-address-book-table thead tr th {
    display: inline-block;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 45%;
  }

  #modal-address-book-table thead tr th:not(:last-child) {
    border: none;
  }

  #modal-address-book-table thead tr th:last-child,
  #modal-address-book-table tr td:last-child {
    display: block;
  }

  #modal-address-book-table tbody th {
    border-top: none;
  }

  #modal-address-book-table tr td {
    display: inline-block;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 0px;
    border-top: none;
    width: 45%;
  }

  #modal-address-book-table tr td:last-child {
    border: none;
    padding-bottom: 5px;
  }

  #modal-address-book-table tbody tr:last-child {
    border-bottom: none;
  }

  .modal-dialog.modal-xl {
    margin: 1.5rem;
    max-width: none;
  }
}

@media only screen and (min-width: 768px) {
  #modal-address-book-table tbody tr {
    height: 70px;
  }

  #modal-address-book-table tbody {
    padding-right: 15px;
  }

  #modal-address-book-table tbody th,
  #modal-address-book-table thead th:first-child {
    width: 9%;
  }

  #address-book .button-container:last-child {
    float: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .results-label {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  #name-header,
  #company-header,
  #location-header {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .pagination-container {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  .results-dropdown-container {
    width: 100%;
    justify-content: center;
  }

  .modal-dialog.modal-xl {
    max-width: none;
    width: 92%;
  }

  #address-book a.btn-primary,
  #address-book button.btn-primary {
    min-width: auto;
  }
}

@media only screen and (min-width: 1159px) {
  .pagination-container {
    width: 50%;
  }

  .results-dropdown-container {
    width: 50%;
  }
}

/********** MODAL **********/
.modal {
  padding-right: 0px !important;
}
.modal-container {
  background-color: #fff;
  border-radius: 0px;
  padding: 30px;
}

/********** SEARCH NUMBER **********/
button.btn.btn-default.search {
  background: transparent url(images/search.svg) no-repeat;
  background-size: 25px 25px;
  background-position: 7px 7px;
}

#tracking-number {
  background: transparent url(images/search.svg) no-repeat;
  background-position: 97% 12px;
  background-size: 21px;
  cursor: text;
}
.modal-dialog {
  min-height: calc(100% - (1.75rem * 2));
  /* margin: 1.75rem auto; */
  display: grid;
  align-items: center;
}

/* .modal-centered {
	min-height: calc(100% - (1.75rem * 2));
    margin: 1.75rem auto;
    display: flex;
    align-items: center;
} */

.modal-header {
  border-bottom: none;
  padding: 0px !important;
  display: inline-block;
}

h3.modal-title {
  padding-bottom: 20px;
  line-height: 1.2;
}

.close {
  opacity: inherit;
}

.modal-header .close {
  opacity: inherit;
  display: inline-block;
  margin-top: -15px;
  margin-right: -15px;
  margin-left: 0px;
  padding: 0px;
}

.close:after,
.modal-header .close:after {
  content: "";
  background-image: url(images/close_x.svg);
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  padding-left: none;
  background-position: 0px 1px;
  width: 15px;
  height: 16px;
}

.modal-body {
  padding: 0px;
}

.modal-dialog.content-center {
  text-align: center;
}

.modal-dialog.medium .button-container {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .modal-header .close {
    margin-top: -8px;
    margin-right: -8px;
  }

  .modal-container {
    padding: 20px;
  }

  /* .modal-dialog {
     	margin: 10px; 
    } */

  .modal-buttons .button-container {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .modal-dialog {
    margin: 1.75rem auto;
  }

  .modal-dialog.small {
    width: 400px !important;
    /* margin: 230px auto; */
  }

  .modal-dialog.medium {
    width: 500px !important;
    /* margin: 12% auto; */
  }

  .modal-dialog.large {
    width: 789px !important;
    /* margin: 12% auto; */
  }

  .modal .button-container:last-child {
    margin-right: 0px;
  }

  .button-container.inline {
    display: inline-block;
    padding-top: 17px;
  }
}

.-header h3 {
  padding-bottom: 10px;
}

.-content {
  padding: 0px;
  float: left;
  width: 100%;
}

.-content p + p,
.-content li + li {
  float: left;
  width: 100%;
  font-size: 14px;
  padding-top: 15px;
}

.-content ul {
  padding-left: 16px;
  list-style: disc;
}

.arrow {
  border-top-color: rgba(0, 0, 0, 0.2) !important;
}
.top > .arrow:after {
  margin-bottom: -1px !important;
  z-index: 1011;
  bottom: 3px;
}

.close:after {
  background-size: 13px 13px;
}

/********** GO-TO NAVIGATION **********/
.dropdown-backdrop {
  position: inherit;
}

.btn-default {
  color: #333366;
  background-color: #fff;
  border-color: #333366;
  height: 44px;
  width: 100%;
  text-align: left;
  padding: 6px 20px;
  border-radius: 3px;
  font-size: 15px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: #333366;
  background-color: #fff;
  border-color: #333366;
}

.btn-default.focus,
.btn-default:focus {
  color: #333366;
  background-color: #fff;
  border-color: #333366;
}

.btn-default:hover {
  color: #333366;
  background-color: #fff;
  border-color: #333366;
}

.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  color: #333366;
  background-color: #fff;
  border-color: #333366;
  font-size: 15px;
}

.btn.active,
.btn:active {
  box-shadow: none;
}

.dropdown-menu {
  width: 100%;
  border: 1px solid #333366;
  border-radius: 0px 0px 3px 3px;
  margin: -2px 0 0;
  padding: 0px 0;
  position: relative;
  font-size: 14px;
}

.dropdown-selection .dropdown-menu {
  width: initial;
  left: 0px;
  position: absolute;
  min-width: 100%;
  overflow-y: auto;
  max-height: 200px;
}

.dropdown-selection {
  position: relative;
}

.dropdown-menu > li > a,
.dropdown-menu > li > button,
.dropdown-menu > button > a {
  display: block;
  padding: 8px 10px;
  clear: both;
  color: #333366;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  white-space: nowrap;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > button:focus,
.dropdown-menu > li > button:hover,
.dropdown-menu > button > a:focus,
.dropdown-menu > button > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #333366;
}

.go-to-wrapper .dropdown-menu > button {
  padding: 0;
}

.dropdown-menu .dropdown-menu {
  display: list-item;
  text-indent: 20px;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #333366;
  text-decoration: none;
  background-color: #fff;
  outline: 0;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.dropup,
.dropdown.navigation {
  position: relative;
}

.dropdown.navigation {
  background-image: none;
}

.dropdown-items-wrapper {
  color: #333366;
  border-top: 1px solid;
  background-color: #fff;
  border-color: #333366;
  height: 44px;
  width: 100%;
  text-align: left;
  padding: 6px 10px;
  border-radius: 3px;
  font-size: 15px;
  -moz-appearance: window;
  -webkit-appearance: none;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  display: inline-block;
  position: relative;
  background: transparent url(images/blue-caret-down.svg) no-repeat;
  background-position: right 10px top 13px;
  background-size: 18px 18px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;
  vertical-align: initial;
}

.dropdown-items-wrapper:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.dropdown.navigation a.dropdown-toggle {
  color: #333366;
  background-color: #ffffff;
  border: 1px solid #333366;
  vertical-align: bottom;
  padding: 10px 15px !important;
}

li.dropdown-disabled a,
li.dropdown-disabled a:visited,
li.dropdown-disabled a:active,
li.dropdown-disabled a:hover {
  background-color: #ffffff !important;
  color: #595959 !important;
}

/********** PROGRESS BAR **********/
.statusBar.text {
  padding-top: 30px;
}

.customer-shipping-information-step,
.billing-step,
.review-step,
.confirmation-step {
  display: inline-block;
  width: 24%;
  text-align: center;
}

/* everythifrom default css*/

.groups-list .contact-group-header {
  padding-top: 20px;
}

.contact-group-header p {
  color: #333366;
  font-size: 20px;
  padding-left: 20px;
}

.contact-group-header p.active {
  padding-left: 16px;
  border-left: 4px solid #e71921;
}

.contact-groups {
  display: block;
}

.contact-groups div.row {
  padding-top: 10px;
}

.contact-groups .contact-group-name button {
  padding-left: 19px;
  font-size: 15px;
  color: black;
}

.contact-groups .contact-group-name.active button {
  padding-left: 15px;
  border-left: 4px solid #e71921;
}

.contact-sub-group.contact-group-name {
  padding-top: 10px;
  padding-left: 40px;
}

#mobile-contact-select button:active,
#results-amount-address-book:active,
#service-type-dropdown-usa-id:active,
#service-type-dropdown-int-id:active {
  color: #333366;
  background-color: #fff;
  border-color: #333366;
  font-size: 15px;
}

.results-amount {
  padding-right: 40px;
}

#address-book .btn-primary {
  min-width: 175px;
}

#address-book-search-input {
  border-right: none;
}

.input-group-btn .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ab-search-results-text {
  color: #333366;
}

#modal-address-book-table thead {
  display: block;
  padding-right: 25px;
}

@media only screen and (max-width: 767px) {
  #modal-address-book-table {
    margin-top: 15px;
  }

  #modal-address-book-table tbody {
    max-height: 274px;
  }

  #mobile-contact-select,
  .input-group .form-control {
    font-size: 14px;
  }

  #name-header,
  #company-header,
  #location-header {
    font-size: 14px;
  }

  #name-header,
  #company-header,
  #location-header {
    padding-bottom: 5px;
  }

  #modal-address-book-table tbody tr {
    height: 55px;
    border-bottom: 1px solid #999999;
  }

  .results-dropdown-container {
    justify-content: center;
  }

  .pagination-container {
    padding-top: 20px;
  }

  .pagination-container .results-pagination li {
    display: inline-block;
  }

  #modal-address-book-table tbody th,
  #modal-address-book-table thead th:first-child {
    display: table-cell;
    width: 10%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #modal-address-book-table {
    border: 1px solid #999999;
  }

  #modal-address-book-table thead {
    padding-right: 10px;
  }

  #modal-address-book-table thead tr {
    height: auto;
  }

  #modal-address-book-table thead tr th {
    display: inline-block;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 45%;
  }

  #modal-address-book-table thead tr th:not(:last-child) {
    border: none;
  }

  #modal-address-book-table thead tr th:last-child,
  #modal-address-book-table tr td:last-child {
    display: block;
  }

  #modal-address-book-table tbody th {
    border-top: none;
  }

  #modal-address-book-table tr td {
    display: inline-block;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 0px;
    border-top: none;
    width: 45%;
  }

  #modal-address-book-table tr td:last-child {
    border: none;
    padding-bottom: 5px;
  }

  #modal-address-book-table tbody tr:last-child {
    border-bottom: none;
  }

  .modal-dialog.modal-xl {
    margin: 1.5rem;
    max-width: none;
  }
}

@media only screen and (min-width: 768px) {
  #modal-address-book-table tbody tr {
    height: 70px;
  }

  #modal-address-book-table tbody {
    padding-right: 15px;
  }

  #modal-address-book-table tbody th,
  #modal-address-book-table thead th:first-child {
    width: 9%;
  }

  #address-book .button-container:last-child {
    float: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .results-label {
    font-size: 14px;
  }
}

.dropdown-selection .dropdown-menu {
  width: initial;
  left: 0px;
  position: absolute;
  min-width: 100%;
  overflow-y: auto;
  max-height: 200px;
}

.dropdown-selection {
  position: relative;
  background: #ffffff;
}

select.dropdown,
#mobile-contact-select button.dropdown,
.results-dropdown-container .dropdown-selection button.dropdown,
.click-step-five-input-container .dropdown-selection.dropdown > button,
.scan-form-wrapper .scan-form-zip-code .dropdown > button {
  background-image: url("images/blue-caret-down.svg");
  background-repeat: no-repeat;
  background-position: right 10px top 13px;
  background-size: 18px 18px;
  cursor: pointer;
}

.showing-results {
  padding-top: 10px;
  font-size: 16px;
  text-align: right;
  color: #333366;
}

.label-total {
  border-bottom: unset;
  width: 100%;
  float: left;
  background-color: #f7f7f7;
  margin: 0px;
  padding-bottom: 0px;
}

.labels-num p {
  float: right;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  color: #333366;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
}

@media only screen and (max-width: 991px) {
  #name-header,
  #company-header,
  #location-header {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .pagination-container {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  .results-dropdown-container {
    width: 100%;
    justify-content: center;
  }

  .modal-dialog.modal-xl {
    max-width: none;
    width: 92%;
  }

  #address-book a.btn-primary,
  #address-book button.btn-primary {
    min-width: auto;
  }
}

@media only screen and (min-width: 1159px) {
  .pagination-container {
    width: 50%;
  }

  .results-dropdown-container {
    width: 50%;
  }
}

/* Styling for buttons that used to be anchor tags, and their related tags */
@media only screen and (max-width: 767px) {
  .package-items-desc-mobile {
    text-align: left;
  }
}

.button-link {
  background-color: transparent;
  border: none;
  color: #333366;
  outline: 0;
  padding: 0;
  text-decoration: none;
}

.button-link.adv-remove-link {
  padding-left: 20px;
}

.button-link.expand-collapse,
.shipping-restrictions .button-link,
.shipping-prohibitions .button-link {
  width: 100%;
}
.expand-collapse {
  cursor: pointer;
  padding-top: 5px;
  display: block;
}

.expand-collapse:after {
  background: url(images/blue-caret-down.svg) no-repeat 20px 10px;
  content: "";
  background-size: 23px 16px;
  background-position: 0px 0px;
  background-size: 23px 16px;
  height: 15px;
  width: 23px;
  display: inline-block;
  padding-top: 0px;
  margin-bottom: -2px;
  margin-left: 1%;
}

h3.collapsed:after {
  transform: rotate(0deg);
}

h3.expanded:after {
  transform: rotate(180deg);
}

.shipping-restrictions .button-link,
.shipping-prohibitions .button-link {
  text-align: left;
}

.button-link:hover {
  color: #999999;
  opacity: 0.7;
  text-decoration: none;
}

.button-link:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  color: #333366;
  outline: 0;
  text-decoration: none;
}

/* LOYALTY */

#loyaltybar {
  position: relative;
  display: inline-block;
  padding: 0.1em 0em 2em 0em;
  overflow: hidden;
  color: #333366;
  opacity: 1;
}

@media (max-width: 750px) {
  #loyaltybar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65%; /* Change this to change the height of the blue part of the LPA points bar*/
    background-color: #333366;
  }
}

@media (min-width: 751px) {
  #loyaltybar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%; /* Change this to change the height of the blue part of the LPA points bar*/
    background-color: #333366;
  }
}

#loyaltyProgress {
  /*height: calc(100% - 15px);*/
  width: 100%;
  height: 8%; /* Change this to change the height of the grey part of the LPA points bar*/
  padding-top: 0px;
  background-color: #ddd;
}
.ID-yes-checked {
  display: none;
  padding-bottom: 10px;
}

.ID-yes-checked p {
  padding-left: 25px;
}

.ID-yes-checked .checkbox-wrap {
  padding-left: 25px;
  padding-top: 0px;
}

#cnsIDXSModal .expand-collapse-wrapper {
  padding-top: 10px;
}
/* END INFORMED DELIVERY MODAL */

/* OTHER MODALS */
#time-out-modal .modal-buttons,
#postage-due-modal .modal-buttons {
  text-align: center;
}

#gxgTermsConditions ol {
  padding: 0px;
}

#gxgTermsConditions li {
  list-style: none;
  margin-bottom: 10px;
}

@media only screen and (max-width: 770px) {
  .desktopView {
    display: none;
  }
}
@media only screen and (min-width: 769px) {
  .mobileView {
    display: none;
  }
}
.hideAddress {
  display: none;
}
@media only screen and (max-width: 767px) {
  .radio-container-print-labels input[type="radio"] {
    display: inline-block;
    float: left;
    top: 2px;
    outline: none;
    position: relative;
  }

  .radio-container-print-labels {
    float: left;
  }

  input[type="checkbox"],
  input[type="radio"] {
    margin: 0px 0px -3px;
  }

  label.checkbox-text.print-labels-checkbox p {
    padding-left: 30px;
  }

  .button-container.create-another-label {
    padding-left: 0px;
  }

  .confirmation-table-header + .row > div {
    padding-top: 20px;
  }

  .confirmation-item p {
    color: #000000;
  }

  .Payment_Confirmation_Container .btn-primary {
    min-width: 240px;
  }

  .confirmation-item label.checkbox-component {
    padding-top: 0px;
  }

  .shipping-history label.checkbox-component {
    padding-left: 15px;
  }

  .SCAN_Form_Container .gray-box .gray-box-wrapper {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .button-container.create-another-label {
    float: right;
  }

  .confirmation-table {
    padding-top: 30px;
  }

  .confirmation-table-header {
    padding-bottom: 15px;
    border-bottom: 2px solid #e71921;
  }
}

/* END CONFIRMATION */

/*START SCAN FORM OPEN */

.scan-form-open-header {
  padding-top: 30px;
}

.no-open-form {
  display: none;
}

td.actions-container-link a {
  float: left;
}

table.col-lg-12.col-md-12.col-sm-12.form-group.form-group.scan-form-details {
  margin-top: 20px;
}

p.scan-form-details-expiration-time-day {
  color: #333366;
}

button.request-remove.scan-form-label {
  float: left;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px;
}

h2.label-total {
  float: right;
}

p.scan-form-label-total {
  font-size: 18px;
  color: #333366;
}

.gray-box.cancel-scan-form {
  padding-top: 15px;
}

.cancel-scan-form-content {
  padding-top: 30px;
  padding-bottom: 20px;
}

.checkbox-print-scan-form-label {
  padding-top: 20px;
}

.scan-form-table .request-remove {
  float: none;
}

.scan-form-item {
  padding-top: 15px;
  padding-bottom: 15px;
}

.scan-form-table-services {
  padding-bottom: 5px;
}

.open-scan-form-container .button-container .btn-primary {
  min-width: 265px;
}

.scan-form-item p + p {
  padding-top: 0px;
}

.scan-form-table-header p,
.scan-form-table-totals p {
  color: #333366;
}

.scan-form-item p {
  color: #000000;
}

p.mobile-scan-form-shipping-address,
p.mobile-scan-form-service,
p.mobile-scan-form-package-details,
p.mobile-scan-form-price {
  font-size: 12px;
  color: #595959;
}

#scan-form-modal .button-container + .button-container {
  margin-left: 30px;
}

#scan-form-modal .btn-primary {
  min-width: 235px;
}

#scan-remove-all-confirmation-modal .modal-buttons,
#scan-remove-confirmation-modal .modal-buttons {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .scan-form-table-header ~ .row > div,
  .scan-form-table-services div + div {
    padding-top: 20px;
  }

  .scan-form-wrapper .request-remove strong {
    display: none;
  }

  .scan-form-item p {
    color: #000000;
  }

  .modal-footer.scan-form {
    display: block;
  }

  #scan-form-modal .button-container + .button-container {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .scan-form-table {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .scan-form-table-header {
    padding-bottom: 15px;
    border-bottom: 2px solid #e71921;
  }

  p.scan-form-label-total {
    text-align: right;
  }

  #scan-remove-confirmation-modal.modal-dialog,
  #scan-remove-all-confirmation-modal.modal-dialog {
    width: 400px;
  }

  .modal-dialog.scan-form-confirmation-modal {
    max-width: 570px;
  }
}

/* END SCAN FORM OPEN */

/* START (SCAN) OPEN FORM */
.open-scan-form-container {
  padding-top: 15px;
}

/* START MODAL: SCAN FORM CONFIRMATION */
.modal-body.scan-form {
  padding-top: 30px;
}

.modal-footer.scan-form {
  border-top: none;
  padding: 0px 0px 0px 0px;
  justify-content: flex-start;
}

.modal-body.scan-form ul {
  padding-left: 30px;
  list-style: disc;
  padding-top: 16px;
}

.modal-body.scan-form p,
.modal-body.scan-form li + li {
  float: left;
  width: 100%;
  padding-top: 0px;
}

.modal-label {
  padding-top: 20px;
}

@media only scren and (min-width: 768px) {
  .modal-dialog.scan-form-confirmation-modal {
    max-width: 650px;
  }
}

ul.bullet-list.indent li,
ol.number-list.indent li {
  margin-left: 20px;
}

ul.bullet-list + h4 {
  padding-top: 10px;
}

.inline-link-container .inline-link {
  display: table;
}

.inline-link-container a.inline-link + a.inline-link {
  padding-top: 10px;
}

.inline-link {
  color: #333366;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
}

.inline-link.secondary {
  color: #333366;
}

.inline-link.left-chevron:before,
.inline-link.right-chevron:after {
  content: "";
  display: inline-block;
  background-image: url(images/nav-red-chevron.svg);
  background-repeat: no-repeat;
  height: 15px;
  width: 12px;
  background-size: 17px 17px;
  position: relative;
}

.inline-link.left-chevron:before {
  transform: rotate(180deg);
  background-position: 0px 0px;
  top: 4px;
  margin-left: 0px;
}

.inline-link.right-chevron:after {
  background-position: 0px 0px;
  top: 1px;
  right: 0px;
}

a:focus {
  color: #333366;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  text-decoration: none;
}

a.header-tabs:hover,
a.tab-link {
  color: #333366;
}

a.secondary:hover {
  color: #999999;
}

a.inline-link:hover,
a.inline-link.secondary:hover,
a.inline-link.right-chevron:hover,
a.inline-link.left-chevron:hover,
a.close:hover {
  opacity: 0.8;
}

a.inline-link:focus {
  color: #333366;
}

.component-header {
  padding-top: 40px;
  padding-left: 15px;
  width: 100%;
  float: left;
}

select::-ms-expand {
  display: none;
}

.color-wrapper {
  display: inline-block;
  text-align: center;
  width: 100px;
  padding-top: 30px;
}

.color-wrapper p {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.color-wrapper span {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50px;
}

.color {
  margin-top: 2px;
}

.navy-blue.color {
  background: #333366;
}

.light-blue.color {
  background: #333366;
}

.green.color {
  background: #218748;
}

.red.color {
  background: #e71921;
}

.white.color {
  background: #ffffff;
  border: 1px solid #000000;
}

.light-gray.color {
  background: #f7f7f7;
}

.silver-gray.color {
  background: #ededed;
}

.gray.color {
  background: #d8d8d8;
}

.medium-gray.color {
  background: #999999;
}

.dark-gray.color {
  background: #595959;
}

.black.color {
  background: #000000;
}

/* Bootstrap 4.3.1 */

h3.-header {
  font-size: 16px;
}

.-header {
  padding: 0px;
  background-color: #fff;
  border-bottom: none;
  padding-bottom: 15px;
}

.-body {
  padding: 0px;
}

.addOverflowWrap {
  word-wrap: break-word;
}

@media only screen and (max-width: 575px) {
  .modal-dialog {
    min-height: calc(100% - (7.75rem * 2)) !important;
  }

  .offset-col-0 {
    margin-left: 0;
  }

  .offset-col-1 {
    margin-left: 8.333333%;
  }

  .offset-col-2 {
    margin-left: 16.666667%;
  }

  .offset-col-3 {
    margin-left: 25%;
  }

  .offset-col-4 {
    margin-left: 33.333333%;
  }

  .offset-col-5 {
    margin-left: 41.666667%;
  }

  .offset-col-6 {
    margin-left: 50%;
  }

  .offset-col-7 {
    margin-left: 58.333333%;
  }

  .offset-col-8 {
    margin-left: 66.666667%;
  }

  .offset-col-9 {
    margin-left: 75%;
  }

  .offset-col-10 {
    margin-left: 83.333333%;
  }

  .offset-col-11 {
    margin-left: 91.666667%;
  }
}

/* End Bootstrap 4.3.1 */

/* START GENERAL STYLING */
h2.normal {
  padding-top: 25px;
}

h2 + h2 {
  padding-top: 22px;
}

.horizontal-line-container {
  padding-top: 30px;
  margin-top: 0px;
}

.Step_One_Container,
.Step_Two_Container,
.Step_Three_Container,
.Step_Four_Container,
.Step_Five_Container,
.Step_Six_Container,
.Step_Seven_Container,
.Step_Eight_Container,
.Step_Nine_Container,
.Step_Ten_Container,
.Preferences_Greeting_Container,
.Package_Options_Container,
.Shipment_Notifications_Container,
.Notify_Recipient_Shipping_Container,
.Print_Settings_Container,
.Reference_Number_Container,
.Return_Address_Container,
.Shipping_Zip_Code_Container,
.SCAN_Form_Container {
  padding-top: 20px;
}

.go-to-wrapper {
  padding-top: 30px;
  padding-bottom: 5px;
}

.go-to-wrapper button.dropdown-items-wrapper {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.popover-wrapper ul,
.modal-body ul {
  padding-left: 15px;
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
  #address-book {
    max-width: none;
    width: 92%;
  }
}

@media (min-width: 1200px) {
  #address-book {
    max-width: 1140px;
  }
}

#address-book .modal-body ul {
  padding-left: 0px;
}
:after,
:before {
  box-sizing: border-box;
}
@media only screen and (max-width: 767px) {
  .Step_One_Container,
  .Step_Two_Container,
  .Step_Three_Container,
  .Step_Four_Container,
  .Step_Five_Container,
  .Step_Six_Container,
  .Step_Seven_Container,
  .Step_Eight_Container,
  .Step_Nine_Container,
  .Step_Ten_Container,
  .Preferences_Greeting_Container,
  .Package_Options_Container,
  .Shipment_Notifications_Container,
  .Notify_Recipient_Shipping_Container,
  .Print_Settings_Container,
  .Reference_Number_Container,
  .Return_Address_Container,
  .Shipping_Zip_Code_Container,
  .SCAN_Form_Container {
    padding-top: 5px;
  }

  ol {
    list-style: decimal;
  }

  .button-wrapper {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 949px) {
  .customs_forms-main-header h1 {
    width: 100%;
    padding-top: 35px;
  }
}

#tracking-notifications-id,
#ship-from-other-zip-id,
#batch-order-id,
#email-notifications-id,
#text-message-notifications-id,
#expected-delivery-updates-id,
#day-of-delivery-updates-id,
#package-delivered-id,
#available-for-pickup-id,
#delivery-exception-updates-id,
#package-in-transit-updates-id,
#send-recipient-email-id,
#hold-for-pickup-id,
#notify-addressee-id,
#notify-me-id {
  color: #333366;
}

#cns-message-wrap {
  background: url("images/alert-sprite@2x.png") no-repeat scroll 20px 0 / 18px
    auto transparent;
  line-height: 20px;
  margin: 0 auto;
  max-width: 1280px;
  padding-left: 45px;
  padding-right: 20px;
  position: relative;
  top: 10px;
  background-size: 18px auto;
}

#cns-message {
  color: #e51e2b;
  font-size: 13px;
}

/* END GENERAL STYLING */

/********** PAGINATION **********/
.result-list-wrapper {
  text-align: center;
}

.results-per-page {
  text-align: center;
}

.results-pagination li.active {
  color: #ffffff;
  border-radius: 50%;
  background-color: #333366;
}

.results-pagination li {
  font-size: 15px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
  display: inline-block;
  height: 32px;
  line-height: 25px;
  text-align: center;
  width: 31px;
  transition: background-color 0.5s, border-radius 0.5s;
}

.results-pagination li.active button {
  color: #ffffff;
  border-radius: 50%;
  background-color: #333366;
}

.results-pagination li.active button:hover {
  color: #fff !important;
}

li.next {
  background-image: url(images/right-arrow.png);
}

li.next.inactive {
  background-image: url(images/right-arrow-inactive.png);
}

li.next,
li.next.inactive {
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  top: 7px;
  left: 6px;
  width: 15px;
  height: 25px;
}

li.previous {
  background-image: url(images/left-arrow.png);
}

li.previous.inactive {
  background-image: url(images/left-arrow-inactive.png);
}

li.previous,
li.previous.inactive {
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  top: 7px;
  left: -4px;
  width: 15px;
  height: 25px;
}

.results-pagination li button,
.results-pagination li button:hover {
  text-decoration: none;
  color: #333366;
  display: inline-block;
  width: 15px;
  height: 25px;
}

.results-pagination li.hover-item:hover:not(.active) {
  border-radius: 50%;
  background-color: #d7d7d7;
}

.results-pagination li.hover-item.active {
  pointer-events: none;
}

.navigation-text.mobile {
  display: none;
  text-indent: -99999px;
}

.navigation img {
  vertical-align: middle;
}

input.pagination-entered-data {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

/********** BUTTONS **********/
.button-container {
  padding-top: 30px;
}

.btn-primary {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #ffffff;
  background: #333366;
  min-width: 120px;
  text-align: center;
  border-radius: 3px;
  height: 44px;
  text-decoration: none;
  padding: 10px 20px;
  border-style: none;
  border: 1px solid #333366;
}

.btn-primary.button--white {
  color: #333366;
  background: #ffffff;
  border: 1px #333366 solid;
  border-radius: 3px;
}

.btn-primary.button--green {
  color: #ffffff;
  background: #218748;
  border: 1px #218748 solid;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #ffffff;
  background-color: #333366;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:active {
  color: #ffffff;
  background-color: #333366;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.btn-primary.button--white.focus,
.btn-primary.button--white:focus,
.btn-primary.button--white:active {
  color: #333366;
  background-color: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.btn-primary.button--green.focus,
.btn-primary.button--green:focus,
.btn-primary.button--green:active {
  color: #ffffff;
  background-color: #218748;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.btn-primary:hover,
.btn-primary.focus:hover,
.btn-primary:focus:hover {
  background: #ededed;
  color: #333366;
  cursor: pointer;
  border: 1px solid #ededed;
}

.btn-primary.button--white:hover {
  background: #ededed;
  border: 1px solid #333366;
}

.btn-primary.button--green:hover {
  background: #ededed;
  color: #218748;
  border: 1px #ededed solid;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  /* -webkit-appearance: button; */

  -webkit-appearance: inherit;
}

.btn-primary.button--white:not(:disabled):not(.disabled).active,
.btn-primary.button--white:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #ffffff;
  border-color: inherit;
  color: #333366;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #333366;
  border-color: #333366;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

/* SERVICE OPTIONS PAGE */
label.best-service {
  cursor: pointer;
}

label.best-service.selected {
  border-bottom: 3px solid red;
  cursor: default;
}

@media only screen and (min-width: 768px) {
  .service-type-tabs {
    display: flex;
    float: left;
    margin-left: 205px;
    margin-top: -60px;
  }

  .service-type-tabs li.selected {
    border-bottom: 3px solid red;
    pointer-events: none;
  }
}

@media only screen and (max-width: 767px) {
  .text-select-a-service {
    display: block;
    margin-bottom: 15px;
  }

  .service-type-tabs {
    border: 1px solid #336;
  }

  ul li.service-type-tab {
    display: block;
    margin-left: 15px !important;
    margin-right: 15px;
  }
}

.method-selection label input.grid-item {
  display: block;
}

.services-empty-table-div {
  display: block;
}

#services-empty-table {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

a.select-service-at-post-office-link {
  text-decoration: none;
}
/* END SERVICE OPTIONS PAGE */

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex-direction: column;
}

/* @media only screen and (max-width: 767px) {
  #root {
    height: 200%;
  }
} */

.btn-primary {
  height: 30px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 12px !important;
}
