/* Grid/Reset Overrides */
button:active,
button:focus,
.bbutton:active,
.bbutton:focus {
  outline: none;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

p,
ul,
ol {
  margin-bottom: 1em;
}

b,
strong {
  font-weight: normal;
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;
}

i,
em {
  font-style: italic;
}

p:empty {
  display: none;
}

img,
iframe,
object,
embed,
video {
  max-width: 100%;
}

ul:last-child,
ol:last-child,
p:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

/* Custom Styles */
.clear {
  clear: both;
}

.section {
  clear: both;
  float: left;
  width: 100%;
  background: #fff;
}

.subsection {
  clear: both;
  float: left;
  width: 100%;
}

.ir {
  float: left;
  width: 100%;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  overflow: hidden;
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
}

.href {
  cursor: pointer;
}

.hidden,
.hide,
.mobile-only,
.mobileonly,
.phone-only,
.phoneonly {
  display: none;
}

.alignvertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

textarea {
  resize: vertical;
}

.strong,
.bold {
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;
  color: #000;
}

.floating-label {
  position: relative;
  margin-bottom: 1rem;
}

.floating-label > input,
.floating-label > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.floating-label > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.floating-label input::-webkit-input-placeholder {
  color: transparent;
}

.floating-label input:-ms-input-placeholder {
  color: transparent;
}

.floating-label input::-ms-input-placeholder {
  color: transparent;
}

.floating-label input::-moz-placeholder {
  color: transparent;
}

.floating-label input::placeholder {
  color: transparent;
}

.floating-label input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.floating-label input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label.custom-checkbox {
  line-height: normal;
}

label.custom-checkbox input {
  display: none;
}
label.custom-checkbox span {
  display: block;
  position: relative;
}

label.custom-checkbox span:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 18px;
  height: 18px;
  border: 1px solid #999999;
  margin-right: 5px;
  background: #ffffff;
  border-radius: 2px;
}

label.custom-checkbox input + span:after {
  content: "\f00c";
  font-weight: 900;
  font-family: Font awesome\5 Free;
  position: absolute;
  top: 2px;
  left: 3px;
  font-size: 12px;
}

label.custom-checkbox input:not(:checked) + span:after {
  content: "";
}

label.custom-radio {
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label.custom-radio input {
  display: none;
}

label.custom-radio span {
  display: inline-block;
  border-radius: 2px;
  padding: 5px;
  border: 1px solid #999999;
  background: #fff;
}

label.custom-radio input:checked + span {
  color: #fff;
  border-color: #336;
  background: #336;
}

.no-margin {
  margin: 0;
}

*,
*:active,
*:focus {
  outline: none;
}

label {
  display: block;
  margin: 0;
}

label.label-ib {
  display: inline-block;
}

.modal-body {
  text-align: left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/*************** Popover Styling ***************/
.popover {
  border-radius: 2px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  /* max-width: 285px; */
}

.popover-header {
  background-color: transparent;
  border-bottom: none;
  font-size: 1.125rem;
}
